import React, { useState } from 'react';
import { TelegramIcon, XIcon, FacebookIcon, WhatsAppIcon, CopyIcon, CheckIcon } from './Icons';
import { FaGift } from "react-icons/fa6";
import '../../styles/ShareDialog.css';

const ShareDialog = ({ isOpen, onClose, inviteLink, inviteMessage }) => {
  const [copied, setCopied] = useState(false);

  if (!isOpen) return null;

  const shareOptions = [
    { 
      name: 'Telegram', 
      icon: <TelegramIcon />,
      iconClass: 'share-icon-telegram',
      action: () => {
        if (window.Telegram && window.Telegram.WebApp) {
          window.Telegram.WebApp.openTelegramLink(`https://t.me/share/url?text=${encodeURIComponent(inviteMessage)}`);
        } else {
          console.error('Telegram WebApp is not available');
        }
      }
    },
	{ 
      name: 'X', 
      icon: <XIcon />,
      iconClass: 'share-icon-x',
      action: () => window.Telegram.WebApp.openLink(`https://twitter.com/intent/tweet?text=${encodeURIComponent(inviteMessage)}`)
    },
    { 
      name: 'WhatsApp', 
      icon: <WhatsAppIcon />,
      iconClass: 'share-icon-whatsapp',
      action: () => window.Telegram.WebApp.openLink(`https://wa.me/?text=${encodeURIComponent(inviteMessage)}`)
    },
    { 
      name: 'Facebook', 
      icon: <FacebookIcon />,
      iconClass: 'share-icon-facebook',
      action: () => window.Telegram.WebApp.openLink(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(inviteLink)}`)
    },
	{ 
      name: 'Copy', 
      icon: copied ? <CheckIcon /> : <CopyIcon />,
      iconClass: 'share-icon-copy',
      action: () => {
        navigator.clipboard.writeText(inviteLink).then(() => {
          setCopied(true);
          setTimeout(() => setCopied(false), 2000);
        });
      }
    },
  ];

  return (
    <div className="share-dialog-overlay">
      <div className="share-dialog">
        <button onClick={onClose} className="close-button">×</button>
        <div className='gift'>
          <FaGift color={'orange'} size={40} />
        </div>
        <h2>Invite & Earn</h2>
        <div className="invite-details">
          <p>Invite friends, get rewards:</p>
          <ul>
            <li>5 passes & 15,000 coins for Premium users</li>
            <li>2 passes & 10,000 coins per friend</li>
          </ul>
        </div>
        <div className="share-options">
          {shareOptions.map((option, index) => (
            <button key={index} onClick={option.action} className="share-option">
              <span className={`share-icon ${option.iconClass}`}>{option.icon}</span>
              <span>{option.name}</span>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ShareDialog;