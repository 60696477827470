import React, { useState, useEffect } from 'react';
import { getUserNFTs } from '../../services/nftService';
import './MyNFTs.css'; 
import liteImage from '../../styles/img/lite.png';
import proImage from '../../styles/img/pro.png';

const MyNFTs = () => {
  const [nfts, setNFTs] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchNFTs = async () => {
      try {
        const userNFTs = await getUserNFTs();
        // Group NFTs by nft_id and calculate total quantity
        const groupedNFTs = userNFTs.reduce((acc, nft) => {
          if (!acc[nft.nft_id]) {
            acc[nft.nft_id] = {
              ...nft.nfts,
              quantity: 0,
              token_ids: []
            };
          }
          acc[nft.nft_id].quantity += nft.quantity;
          acc[nft.nft_id].token_ids.push(nft.token_id);
          return acc;
        }, {});
        setNFTs(Object.values(groupedNFTs));
      } catch (error) {
        console.error('Error fetching user NFTs:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchNFTs();
  }, []);

  const getImageSource = (nftName) => {
    if (nftName.toLowerCase().includes('lite')) {
      return liteImage;
    } else if (nftName.toLowerCase().includes('pro')) {
      return proImage;
    }
    return nftName.image_url; // fallback to the nft.image_url if provided
  };

  if (loading) {
    return (
      <div className="loading-screen">
        <svg 
          className="loading-icon" 
          x="0px" 
          y="0px" 
          viewBox="0 0 37 37" 
          height="37" 
          width="37" 
          preserveAspectRatio="xMidYMid meet"
        >
          <path 
            className="track" 
            fill="none" 
            strokeWidth="5" 
            pathLength="100" 
            d="M0.37 18.5 C0.37 5.772 5.772 0.37 18.5 0.37 S36.63 5.772 36.63 18.5 S31.228 36.63 18.5 36.63 S0.37 31.228 0.37 18.5">
          </path>
          <path 
            className="car" 
            fill="none" 
            strokeWidth="5" 
            pathLength="100" 
            d="M0.37 18.5 C0.37 5.772 5.772 0.37 18.5 0.37 S36.63 5.772 36.63 18.5 S31.228 36.63 18.5 36.63 S0.37 31.228 0.37 18.5">
          </path>
        </svg>
      </div>
    );
  }

  if (nfts.length === 0) {
    return <div className="no-nfts">You don't own any NFTs yet.</div>;
  }

  return (
    <div className="my-nfts">
      <h2>My NFT Collection</h2>
      <div className="nft-grid">
        {nfts.map((nft) => (
          <div key={nft.id} className="nft-item">
            <div className="nft-image-container">
              <img src={getImageSource(nft.name)} alt={nft.name} className="nft-image" />
            </div>
            <h3>{nft.name}</h3>
            <p>Quantity: {nft.quantity}</p>
            <p className="token-ids">Token IDs: {nft.token_ids.join(', ')}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MyNFTs;