import api from './api';

export const getNFTCatalogue = async () => {
  try {
    const response = await api.get('/nfts/catalogue');
    return response.data;
  } catch (error) {
    console.error('Error fetching NFT catalogue:', error);
    throw error;
  }
};

export const buyNFT = async (nftId) => {
  try {
    const response = await api.post('/nfts/buy', { nftId });
    return response.data;
  } catch (error) {
    console.error('Error buying NFT:', error);
    throw error;
  }
};

export const getUserNFTs = async () => {
  try {
    const response = await api.get('/nfts/user');
    return response.data;
  } catch (error) {
    console.error('Error fetching user NFTs:', error);
    throw error;
  }
};