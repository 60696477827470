import React, { useState, useEffect } from 'react';
import { getNFTCatalogue, getUserNFTs, initiateOxapayPayment } from '../services/api';
import MyNFTs from '../components/NFTs/MyNFTs';
import OxapayCheckout from '../components/Checkout/OxapayCheckout';
import { usePurchaseContext } from '../contexts/PurchaseContext';
import { FaCoins, FaShoppingCart, FaExchangeAlt, FaWallet, FaUser } from 'react-icons/fa';
import '../styles/NFTs.css';
import liteImage from '../styles/img/lite.png';
import proImage from '../styles/img/pro.png';

const NFTCard = ({ nft, onBuyClick, onCompareClick }) => {
  const [showBenefits, setShowBenefits] = useState(false);

  const getImageSource = () => {
    if (nft.name.toLowerCase().includes('lite')) {
      return liteImage;
    } else if (nft.name.toLowerCase().includes('pro')) {
      return proImage;
    }
    return nft.image;
  };

  return (
    <div className="nft-card">
      <div className="nft-image-container">
        <img src={getImageSource()} alt={nft.name} className="nft-image" />
      </div>
      <div className="nft-info">
        <h3>{nft.name}</h3>
        <p className="nft-price"><FaCoins /> ${nft.price}</p>
        <button onClick={() => setShowBenefits(!showBenefits)} className="benefits-button">
          {showBenefits ? 'Hide Benefits' : 'Show Benefits'}
        </button>
        {showBenefits && (
          <div className="nft-benefits">
            <ul>
              <li>Exclusive access to Miniverse events</li>
              <li>Special in-game items</li>
              <li>Voting rights in Miniverse DAO</li>
            </ul>
          </div>
        )}
        <button onClick={() => onBuyClick(nft)} className="buy-button">
          <FaShoppingCart /> Buy Now
        </button>
        <button onClick={() => onCompareClick(nft)} className="compare-button">
          <FaExchangeAlt /> Compare
        </button>
      </div>
    </div>
  );
};

const ComparisonModal = ({ nfts, onClose }) => {
  return (
    <div className="comparison-modal">
      <div className="comparison-content">
        <h2>NFT Comparison</h2>
        <table className="comparison-table">
          <thead>
            <tr>
              <th>Feature</th>
              {nfts.map(nft => <th key={nft.id}>{nft.name}</th>)}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Price</td>
              {nfts.map(nft => <td key={nft.id}>${nft.price}</td>)}
            </tr>
            <tr>
              <td>Rarity</td>
              {nfts.map(nft => <td key={nft.id}>{nft.rarity}</td>)}
            </tr>
            <tr>
              <td>Event Access</td>
              {nfts.map(nft => <td key={nft.id}>{nft.eventAccess ? '✓' : '✗'}</td>)}
            </tr>
            <tr>
              <td>In-game Items</td>
              {nfts.map(nft => <td key={nft.id}>{nft.inGameItems ? '✓' : '✗'}</td>)}
            </tr>
            <tr>
              <td>DAO Voting Rights</td>
              {nfts.map(nft => <td key={nft.id}>{nft.daoVoting ? '✓' : '✗'}</td>)}
            </tr>
          </tbody>
        </table>
        <button onClick={onClose} className="close-button">Close</button>
      </div>
    </div>
  );
};

const NFTs = () => {
  const [nfts, setNfts] = useState([]);
  const [userNFTs, setUserNFTs] = useState([]);
  const [activeTab, setActiveTab] = useState('mint');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedNFT, setSelectedNFT] = useState(null);
  const [comparisonNFTs, setComparisonNFTs] = useState([]);
  const [checkoutUrl, setCheckoutUrl] = useState(null);
  const { setPurchaseId } = usePurchaseContext();

  useEffect(() => {
    const fetchNFTs = async () => {
      try {
        setLoading(true);
        const [catalogueData, userNftsData] = await Promise.all([
          getNFTCatalogue(),
          getUserNFTs()
        ]);
        setNfts(catalogueData);
        setUserNFTs(userNftsData);
      } catch (error) {
        console.error('Failed to fetch NFT data:', error);
        setError('Failed to load NFTs. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchNFTs();
  }, []);

  const handleBuyClick = (nft) => {
    setSelectedNFT(nft);
  };

  const handleCompareClick = (nft) => {
    if (comparisonNFTs.length < 2) {
      setComparisonNFTs([...comparisonNFTs, nft]);
    } else {
      setComparisonNFTs([comparisonNFTs[1], nft]);
    }
  };

  const handleConfirmPurchase = async () => {
    try {
      const response = await initiateOxapayPayment(selectedNFT.id, 1);
      if (response.paymentData && response.paymentData.payLink) {
        setCheckoutUrl(response.paymentData.payLink);
        setPurchaseId(response.purchaseId);
        setSelectedNFT(null);
      } else {
        throw new Error('Invalid payment data received from the server');
      }
    } catch (error) {
      console.error('Purchase error:', error);
      setError(`Error during purchase: ${error.message}`);
    }
  };

  const handleCancelPayment = () => {
    setCheckoutUrl(null);
    setPurchaseId(null);
  };

  if (loading) {
    return (
      <div className="loading-screen">
        <svg className="loading-icon" viewBox="0 0 37 37">
          <path className="track" d="M0.37 18.5 C0.37 5.772 5.772 0.37 18.5 0.37 S36.63 5.772 36.63 18.5 S31.228 36.63 18.5 36.63 S0.37 31.228 0.37 18.5" />
          <path className="car" d="M0.37 18.5 C0.37 5.772 5.772 0.37 18.5 0.37 S36.63 5.772 36.63 18.5 S31.228 36.63 18.5 36.63 S0.37 31.228 0.37 18.5" />
        </svg>
      </div>
    );
  }

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  if (checkoutUrl) {
    return (
      <OxapayCheckout 
        paymentUrl={checkoutUrl} 
        onCancel={handleCancelPayment}
      />
    );
  }

  return (
    <div className="nfts-page">
      <div className="wallet-tab-menu">
        <button
          className={`tab-button ${activeTab === 'mint' ? 'active' : ''}`}
          onClick={() => setActiveTab('mint')}
        >
          <FaWallet /> Mint NFT
        </button>
        <button
          className={`tab-button ${activeTab === 'my-nfts' ? 'active' : ''}`}
          onClick={() => setActiveTab('my-nfts')}
        >
          <FaUser /> My NFTs
        </button>
      </div>

      {activeTab === 'mint' && (
        <div className="mint-section">
          <div className="nft-grid">
            {nfts.map((nft) => (
              <NFTCard 
                key={nft.id} 
                nft={nft} 
                onBuyClick={handleBuyClick} 
                onCompareClick={handleCompareClick}
              />
            ))}
          </div>
        </div>
      )}

      {activeTab === 'my-nfts' && <MyNFTs nfts={userNFTs} />}

      {selectedNFT && (
        <div className="checkout-modal">
          <div className="checkout-content">
            <h2>Confirm Purchase</h2>
            <img src={selectedNFT.image} alt={selectedNFT.name} className="checkout-nft-image" />
            <h3>{selectedNFT.name}</h3>
            <p>Price: ${selectedNFT.price}</p>
            <div className="checkout-buttons">
              <button onClick={() => setSelectedNFT(null)} className="cancel-button">Cancel</button>
              <button onClick={handleConfirmPurchase} className="confirm-button">Confirm Purchase</button>
            </div>
          </div>
        </div>
      )}

      {comparisonNFTs.length === 2 && (
        <ComparisonModal 
          nfts={comparisonNFTs} 
          onClose={() => setComparisonNFTs([])} 
        />
      )}
    </div>
  );
};

export default NFTs;