// ShyBirdGame.js

import React, { useEffect, useRef } from 'react';
import { useUser } from '../../contexts/UserContext';
import '../../styles/shybird.css';

const ShyBirdGame = () => {
  const containerRef = useRef(null);
  const { user, setUser } = useUser();

  useEffect(() => {
    let cleanup;

    const loadScripts = async () => {
      try {
        await loadScript('https://cdnjs.cloudflare.com/ajax/libs/three.js/r128/three.min.js');
        await loadScript('https://cdnjs.cloudflare.com/ajax/libs/gsap/3.9.1/gsap.min.js');
        
        await new Promise(resolve => setTimeout(resolve, 100));
        
        const { initShyBirdGame } = await import('./shybirdLogic');
        cleanup = initShyBirdGame(containerRef.current);
      } catch (error) {
        console.error('Error loading scripts:', error);
      }
    };

    loadScripts();

    return () => {
      if (cleanup) cleanup();
    };
  }, []);

  const loadScript = (src) => {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = src;
      script.async = true;
      script.onload = resolve;
      script.onerror = reject;
      document.body.appendChild(script);
    });
  };

  return (
    <div id="shy-bird-game">
      <div id="world" ref={containerRef}></div>
      <div id="instructions">
        ^<br/>Move the head of this bird<br/>
        <span className="lightInstructions">and watch how the 2 others<br/>interact with him</span>
      </div>
    </div>
  );
};

export default ShyBirdGame;