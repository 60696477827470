import React, { useEffect, useCallback, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../contexts/UserContext';
import { useAppContext } from '../contexts/AppContext';
import { getGames, fetchCoinPoolBalance } from '../services/api';
import '../styles/Games.css';
import sendIcon from '../styles/img/send.svg';
import ShareDialog from '../components/Layout/ShareDialog';

// Banner images
import chillTheLionBanner from '../styles/img/chill-the-lion-banner.png';
import rabbitGameBanner from '../styles/img/rabbit-game-banner.png';

const MAX_POOL = 10000;
const REFRESH_INTERVAL = 5000; // 5 seconds

const Games = () => {
  const navigate = useNavigate();
  const { user } = useUser();
  const { 
    games, 
    setGames, 
    isLoading, 
    startLoading, 
    stopLoading, 
    error, 
    setError,
    clearError,
    coinPoolBalance,
    setCoinPoolBalance
  } = useAppContext();

  const [isShareDialogOpen, setIsShareDialogOpen] = useState(false);

  const potionContentRef = useRef(null);

  useEffect(() => {
    if (window.Telegram?.WebApp) {
      window.Telegram.WebApp.setHeaderColor('#000000');
    }
  }, []);

  const fetchGames = useCallback(async () => {
    try {
      startLoading();
      clearError();
      const fetchedGames = await getGames();
      setGames(fetchedGames);
    } catch (err) {
      console.error('Failed to fetch games:', err);
      setError('Failed to load games. Please try again later.');
    } finally {
      stopLoading();
    }
  }, [startLoading, stopLoading, setGames, setError, clearError]);

  const fetchPoolBalance = useCallback(async () => {
    try {
      const balance = await fetchCoinPoolBalance();
      setCoinPoolBalance(balance);
    } catch (error) {
      console.error('Failed to fetch coin pool balance:', error);
    }
  }, [setCoinPoolBalance]);

  useEffect(() => {
    fetchGames();
    fetchPoolBalance();
    const intervalId = setInterval(fetchPoolBalance, REFRESH_INTERVAL);
    return () => clearInterval(intervalId);
  }, [fetchGames, fetchPoolBalance]);

  useEffect(() => {
    if (potionContentRef.current) {
      const fillPercentage = (coinPoolBalance / MAX_POOL) * 100;
      potionContentRef.current.style.setProperty('--fill-percentage', `${fillPercentage}%`);
    }
  }, [coinPoolBalance]);

  const preventDefaultAndNavigate = useCallback((e, path) => {
    e.preventDefault();
    e.stopPropagation();
    navigate(`/games/${path}`);
  }, [navigate]);

  const handleInvite = () => {
    setIsShareDialogOpen(true);
  };

  const handleCloseShareDialog = () => {
    setIsShareDialogOpen(false);
  };

  const inviteLink = `https://t.me/EdgeverseBot/app?startapp=ref_${user?.referral_code}`;
  const inviteMessage = `Join me on Edgeverse! Use my referral link: ${inviteLink}`;

  const getBannerImage = (gameName) => {
    switch (gameName.toLowerCase()) {
      case 'chill the lion':
        return chillTheLionBanner;
      case 'rabbit game':
        return rabbitGameBanner;
      default:
        return null;
    }
  };

  if (isLoading) {
    return (
      <div className="loading-screen">
        <svg 
          className="loading-icon" 
          x="0px" 
          y="0px" 
          viewBox="0 0 37 37" 
          height="37" 
          width="37" 
          preserveAspectRatio="xMidYMid meet"
        >
          <path 
            className="track" 
            fill="none" 
            strokeWidth="5" 
            pathLength="100" 
            d="M0.37 18.5 C0.37 5.772 5.772 0.37 18.5 0.37 S36.63 5.772 36.63 18.5 S31.228 36.63 18.5 36.63 S0.37 31.228 0.37 18.5">
          </path>
          <path 
            className="car" 
            fill="none" 
            strokeWidth="5" 
            pathLength="100" 
            d="M0.37 18.5 C0.37 5.772 5.772 0.37 18.5 0.37 S36.63 5.772 36.63 18.5 S31.228 36.63 18.5 36.63 S0.37 31.228 0.37 18.5">
          </path>
        </svg>
      </div>
    );
  }

  if (error) {
    return (
      <div className="games-error-message">
        {error}
        <button onClick={fetchGames}>Try Again</button>
      </div>
    );
  }

  return (
    <div className="games-page-container">
      <div className="games-page-header">
        <div className="games-balance-meter">
          <div className="potion" ref={potionContentRef}>
            <div className="potion-liquid" style={{height: '50%'}}></div>
          </div>
          <div className="games-balance-display">
            {Math.floor(coinPoolBalance)}/{MAX_POOL}
          </div>
        </div>
        <div className="game-pass-ticket">
          <div className="ticket-left">
            <div className="pass-number">{user?.game_passes || 0}</div>
          </div>
          <div className="ticket-right">
            <div className="pass-title">PASSES</div>
          </div>
        </div>
      </div>
      
      <div className="games-list-container">
        {games.map((game) => (
          <React.Fragment key={game.id}>
            <div className="games-card">
              <img src={getBannerImage(game.name)} alt={game.name} className="games-card-banner" />
              <button
                className="games-play-button"
                onClick={(e) => preventDefaultAndNavigate(e, game.path)}
              >
                Play <img src={sendIcon} alt="Play" className="send-icon" />
              </button>
            </div>
            {game.name === 'Rabbit Game' && (
              <div className='invite-friend'>
                <span>Invite your friends to earn more passes</span>
                <button className="invite-button" onClick={handleInvite}>
                  Invite
                </button>
              </div>
            )}
          </React.Fragment>
        ))}
      </div>

      <ShareDialog
        isOpen={isShareDialogOpen}
        onClose={handleCloseShareDialog}
        inviteMessage={inviteMessage}
        inviteLink={inviteLink}
      />
    </div>
  );
};

export default Games;