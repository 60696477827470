import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useUser } from '../../contexts/UserContext';
import { useAppContext } from '../../contexts/AppContext';
import { useNavigate } from 'react-router-dom';
import {
  startRabbitGame,
  endRabbitGame,
  getUserStats,
} from '../../services/api';
import '../../styles/RabbitGame.css';

const GAME_DURATION = 120; // 2 minutes

const RabbitGame = () => {
  const iframeRef = useRef(null);
  const { user, updateUser } = useUser();
  const [gameUrl, setGameUrl] = useState('');
  const [score, setScore] = useState(0);
  const [timeLeft, setTimeLeft] = useState(GAME_DURATION);
  const [gameOver, setGameOver] = useState(false);
  const [gameStarted, setGameStarted] = useState(false);
  const [gameResult, setGameResult] = useState(null);
  const [userStats, setUserStats] = useState(null);
  const [showGameOverPopup, setShowGameOverPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [latestPasses, setLatestPasses] = useState(null);
  const navigate = useNavigate();
  const { startGlobalLoading, stopGlobalLoading, setError } = useAppContext();

  const updateUserContext = useCallback(async () => {
    try {
      const latestStats = await getUserStats();
      if (latestStats) {
        updateUser(latestStats);
        setUserStats(latestStats);
        return latestStats;
      } else {
        throw new Error('Failed to fetch user stats');
      }
    } catch (error) {
      console.error('Failed to update user context:', error);
      setError('Failed to update user stats. Please try again.');
    }
  }, [updateUser, setError]);

  const fetchLatestPasses = useCallback(async () => {
    try {
      const latestStats = await getUserStats();
      if (latestStats && latestStats.game_passes !== undefined) {
        setLatestPasses(latestStats.game_passes);
        return latestStats.game_passes;
      } else {
        throw new Error('Failed to fetch latest passes');
      }
    } catch (error) {
      console.error('Failed to fetch latest passes:', error);
      setError('Failed to fetch latest passes. Please check your profile for accurate information.');
    }
  }, [setError]);

  const loadGameContent = useCallback(() => {
    const cssUrl = 'https://cdn.jsdelivr.net/gh/Bharathcoorg/logos@main/rabbit.css';

    fetch(cssUrl)
      .then(response => response.text())
      .then(cssContent => {
        const gameHtml = `
          <!DOCTYPE html>
          <html lang="en">
          <head>
              <meta charset="UTF-8">
              <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no">
              <title>Rabbit Game</title>
              <style>
                ${cssContent}
              </style>
              <style>
                body, html {
                  margin: 0;
                  padding: 0;
                  overflow: hidden;
                  width: 100%;
                  height: 100%;
                }
                #world {
                  width: 100%;
                  height: 100%;
                  position: fixed;
                  top: 0;
                  left: 0;
                }
                #instructions {
                  bottom: 40px !important;
                }
                #game-over-popup, #time-over-popup {
                  position: fixed;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  background-color: rgba(0, 0, 0, 0.8);
                  color: white;
                  padding: 20px;
                  border-radius: 15px;
                  box-shadow: 0 0 20px rgba(0,0,0,0.3);
                  text-align: center;
                  max-width: 90%;
                  width: 300px;
                  z-index: 1002;
                  display: none;
                }
                #game-over-popup h2, #time-over-popup h2 {
                  color: white;
                  margin-top: 0;
                }
                #game-over-popup p, #time-over-popup p {
                  color: white;
                  margin-bottom: 10px;
                }
                #game-over-popup button, #time-over-popup button {
                  background-color: #4CAF50;
                  border: none;
                  color: white;
                  padding: 10px 20px;
                  text-align: center;
                  text-decoration: none;
                  display: inline-block;
                  font-size: 16px;
                  margin: 4px 2px;
                  cursor: pointer;
                  border-radius: 5px;
                }
              </style>
          </head>
          <body>
              <div id="world"></div>
              <div id="game-over-popup">
                <h2>Game Over!</h2>
                <p>Your score: <span id="final-score"></span></p>
                <p>Coins earned: <span id="coins-earned"></span></p>
                <p>Passes left: <span id="passes-left"></span></p>
                <p>Game duration: <span id="game-duration"></span></p>
                <p style="font-style: italic;">Earn more passes by inviting friends!</p>
                <button id="retry-button">Retry</button>
                <button id="back-to-games-button">Back to Games</button>
              </div>
              <div id="time-over-popup">
                <h2>Time's Up!</h2>
                <p>You ran out of time!</p>
                <button id="back-to-games-button">Back to Games</button>
              </div>
              <div id="gameoverInstructions">Game Over</div>
              <div id="dist">
                  <div class="label">distance</div>
                  <div id="distValue">000</div>
              </div>
              <div id="instructions">Click to jump<span class="lightInstructions"> — Grab the carrots / avoid the hedgehogs</span></div>
              <div id="timer">Time Left: ${GAME_DURATION}s</div>
              <script src="https://cdn.jsdelivr.net/gh/Bharathcoorg/logos@main/three.js"></script>
              <script src="https://cdn.jsdelivr.net/gh/Bharathcoorg/logos@main/TweenMax.min.js"></script>
              <script src="https://cdn.jsdelivr.net/gh/Bharathcoorg/logos@main/OrbitControls.js"></script>
              <script src="https://cdn.jsdelivr.net/gh/Bharathcoorg/logos@main/game.js"></script>
              <script>
                document.getElementById('retry-button').addEventListener('click', () => {
                  window.parent.postMessage({ type: 'RETRY' }, '*');
                });
                document.getElementById('back-to-games-button').addEventListener('click', () => {
                  window.parent.postMessage({ type: 'BACK_TO_GAMES' }, '*');
                });
                window.addEventListener('load', () => {
                  window.parent.postMessage({ type: 'IFRAME_LOADED' }, '*');
                  if (typeof initGame === 'function') {
                    initGame();
                  }
                });
              </script>
          </body>
          </html>
        `;
        const blob = new Blob([gameHtml], { type: 'text/html' });
        const url = URL.createObjectURL(blob);
        setGameUrl(url);
      })
      .catch(error => {
        console.error('Error loading CSS:', error);
        setError('Failed to load game resources. Please try again.');
      });
  }, [setError]);

  const startGame = useCallback(async () => {
    try {
      startGlobalLoading();
      await updateUserContext();
      const currentUserStats = await getUserStats();
      if (!currentUserStats) {
        throw new Error('Failed to fetch user stats');
      }
      if (currentUserStats.game_passes > 0) {
        const response = await startRabbitGame();
        console.log('Game session started:', response);
        setGameStarted(true);
        setGameOver(false);
        setScore(0);
        setTimeLeft(GAME_DURATION);
        setGameResult(null);
        setShowGameOverPopup(false);
        if (iframeRef.current) {
          iframeRef.current.src = iframeRef.current.src;
        }
        loadGameContent();
      } else {
        alert('You need game passes to play! Go earn some!');
        navigate('/games');
      }
    } catch (error) {
      console.error('Failed to start Rabbit game:', error);
      if (error.response && error.response.status === 400 && error.response.data.error === 'Not enough game passes') {
        alert('You need game passes to play! Go earn some!');
      } else {
        setError('Failed to start the game. Please try again later.');
      }
      navigate('/games');
    } finally {
      stopGlobalLoading();
    }
  }, [navigate, startGlobalLoading, stopGlobalLoading, setError, updateUserContext, loadGameContent]);

  const endGameProcess = useCallback(async (finalScore) => {
    if (gameStarted) {
      try {
        setIsLoading(true);
        const gameDuration = GAME_DURATION - timeLeft;
        console.log('Ending game with score:', finalScore, 'and duration:', gameDuration);
        const result = await endRabbitGame(finalScore);
        console.log('Game ended with result:', result);
        if (result && result.coinsEarned !== undefined) {
          setGameResult(result);
          await updateUserContext();
          const latestPasses = await fetchLatestPasses();
          setLatestPasses(latestPasses);
          setShowGameOverPopup(true);
        } else {
          console.error('Unexpected result format from endRabbitGame:', result);
        }
        setGameStarted(false);
        setGameOver(true);
      } catch (error) {
        console.error('Failed to end Rabbit game:', error);
        setError('Failed to end the game. Please try again.');
        navigate('/games');
      } finally {
        setIsLoading(false);
      }
    }
  }, [gameStarted, timeLeft, setError, updateUserContext, navigate, fetchLatestPasses]);

  const handleExit = useCallback(async () => {
    console.log('handleExit called');
    try {
      setIsLoading(true);
      if (gameStarted) {
        await endGameProcess(score);
      }
      const latestStats = await updateUserContext();
      if (latestStats) {
        updateUser(latestStats); // Update global user context
      }
    } catch (error) {
      console.error('Error during exit:', error);
      setError('An error occurred while exiting the game. Your progress may not have been saved.');
    } finally {
      setIsLoading(false);
      navigate('/games');
    }
  }, [navigate, endGameProcess, score, updateUserContext, updateUser, setError, gameStarted]);

  useEffect(() => {
    console.log('RabbitGame component mounted');
    startGame();

    return () => {
      console.log('RabbitGame component unmounting');
      if (gameUrl) {
        URL.revokeObjectURL(gameUrl);
      }
    };
  }, []);

  useEffect(() => {
    let timerInterval;

    if (gameStarted) {
      timerInterval = setInterval(() => {
        setTimeLeft(prevTime => {
          const newTime = prevTime - 1;
          if (newTime <= 0) {
            clearInterval(timerInterval);
            endGameProcess(score);
          }
          if (iframeRef.current) {
            const timerElement = iframeRef.current.contentWindow.document.getElementById('timer');
            if (timerElement) {
              timerElement.textContent = `Time Left: ${newTime}s`;
            }
          }
          return newTime;
        });
      }, 1000);
    }

    return () => {
      clearInterval(timerInterval);
    };
  }, [gameStarted, endGameProcess, score]);

  useEffect(() => {
    const handleMessage = (event) => {
      console.log('Received message:', event.data);
      if (event.data.type === 'GAME_OVER') {
        console.log("Game over! Final score:", event.data.score);
        setScore(event.data.score);
        setGameOver(true);
        endGameProcess(event.data.score);
      } else if (event.data.type === 'SCORE_UPDATE') {
        console.log("Score update received:", event.data.score);
        setScore(event.data.score);
      } else if (event.data.type === 'RETRY') {
        console.log("Retry requested");
        startGame();
      } else if (event.data.type === 'BACK_TO_GAMES') {
        console.log("Back to games requested");
        handleExit();
      } else if (event.data.type === 'IFRAME_LOADED') {
        console.log('Iframe loaded');
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [endGameProcess, startGame, handleExit]);

  return (
    <div style={{ width: '100vw', height: '100vh', position: 'fixed', top: 0, left: 0, overflow: 'hidden' }}>
      {!showGameOverPopup && (
        <>
          <button 
            className="exit-button" 
            onClick={handleExit} 
            style={{ 
              position: 'absolute', 
              zIndex: 1001, 
              top: '10px', 
              right: '10px',
              background: 'rgba(255, 255, 255, 0.2)',
              color: 'white',
              border: 'none',
              padding: '8px 15px',
              borderRadius: '20px',
              cursor: 'pointer',
              fontSize: '14px',
              backdropFilter: 'blur(5px)',
            }}
          >
            Exit
          </button>
          <div 
            style={{ 
              position: 'absolute', 
              zIndex: 1001, 
              top: '10px', 
              left: '10px',
              background: 'rgba(255, 255, 255, 0.2)',
              color: 'white',
              border: 'none',
              padding: '8px 15px',
              borderRadius: '20px',
              fontSize: '14px',
              backdropFilter: 'blur(5px)',
            }}
          >
            Time Left: {timeLeft}s
          </div>
        </>
      )}
      {gameStarted && !gameOver && (
        <iframe
          ref={iframeRef}
          src={gameUrl}
          style={{
            width: '100%',
            height: '100%',
            border: 'none',
            position: 'absolute',
            top: 0,
            left: 0,
          }}
          title="Rabbit Game"
          scrolling="no"
          seamless="seamless"
        />
      )}
      {(showGameOverPopup || isLoading) && (
        <div className="overlay">
          {isLoading ? (
            <div className="loading-screen">
              <svg 
                className="loading-icon" 
                viewBox="0 0 37 37" 
                preserveAspectRatio="xMidYMid meet"
              >
                <path 
                  className="track" 
                  fill="none" 
                  strokeWidth="5" 
                  pathLength="100" 
                  d="M0.37 18.5 C0.37 5.772 5.772 0.37 18.5 0.37 S36.63 5.772 36.63 18.5 S31.228 36.63 18.5 36.63 S0.37 31.228 0.37 18.5">
                </path>
                <path 
                  className="car" 
                  fill="none" 
                  strokeWidth="5" 
                  pathLength="100" 
                  d="M0.37 18.5 C0.37 5.772 5.772 0.37 18.5 0.37 S36.63 5.772 36.63 18.5 S31.228 36.63 18.5 36.63 S0.37 31.228 0.37 18.5">
                </path>
              </svg>
            </div>
          ) : (
            <div className="game-over-popup">
              <h2>Game Over!</h2>
              <p>Coins earned: {gameResult.coinsEarned}</p>
              <p>Passes left: {latestPasses !== null ? latestPasses : 'Loading...'}</p>
              <p>Game duration: {gameResult.gameDuration.toFixed(2)} seconds</p>
              <button onClick={startGame}>Retry</button>
              <button onClick={handleExit}>Back to Games</button>
              <p className="invite-friends">Earn more passes by inviting friends!</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default RabbitGame;