import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { checkPaymentStatus } from '../../services/api';
import { usePurchaseContext } from '../../contexts/PurchaseContext';
import './OxapayCheckout.css';

const OxapayCheckout = ({ paymentUrl }) => {
  const navigate = useNavigate();
  const { purchaseId } = usePurchaseContext();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!purchaseId) {
      console.error('No purchaseId available in OxapayCheckout');
      return;
    }

    const checkStatus = async () => {
      try {
        const result = await checkPaymentStatus(purchaseId);
        console.log('Payment status:', result);

        if (result.status === 'Paid' || result.status === 'Confirming' || result.internalStatus === 'payment_confirmed') {
          navigate('/payment-processing');
        } else if (result.status === 'Expired' || result.status === 'Cancelled') {
          navigate('/payment-cancelled');
        }
      } catch (error) {
        console.error('Error checking payment status:', error);
      }
    };

    const intervalId = setInterval(checkStatus, 5000);

    return () => clearInterval(intervalId);
  }, [purchaseId, navigate]);

  if (!purchaseId) {
    return <div>Error: No purchase ID available</div>;
  }

  return (
    <div className="oxapay-checkout">
      <div className="checkout-message">
        <p>You will be redirected automatically after successful payment.</p>
      </div>
      {isLoading && (
        <div className="loading-screen">
          <svg 
            className="loading-icon" 
            x="0px" 
            y="0px" 
            viewBox="0 0 37 37" 
            height="37" 
            width="37" 
            preserveAspectRatio="xMidYMid meet"
          >
            <path 
              className="track" 
              fill="none" 
              strokeWidth="5" 
              pathLength="100" 
              d="M0.37 18.5 C0.37 5.772 5.772 0.37 18.5 0.37 S36.63 5.772 36.63 18.5 S31.228 36.63 18.5 36.63 S0.37 31.228 0.37 18.5">
            </path>
            <path 
              className="car" 
              fill="none" 
              strokeWidth="5" 
              pathLength="100" 
              d="M0.37 18.5 C0.37 5.772 5.772 0.37 18.5 0.37 S36.63 5.772 36.63 18.5 S31.228 36.63 18.5 36.63 S0.37 31.228 0.37 18.5">
            </path>
          </svg>
        </div>
      )}
      <iframe 
        src={paymentUrl} 
        title="Oxapay Checkout" 
        className="checkout-iframe" 
        onLoad={() => setIsLoading(false)}
      />
    </div>
  );
};

export default OxapayCheckout;