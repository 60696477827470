import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useUser } from '../../contexts/UserContext';
import { useAppContext } from '../../contexts/AppContext';
import { fetchCoinPoolBalance, updateHoldToEarn, claimCoins } from '../../services/api';
import { useNavigate, useLocation } from 'react-router-dom';
import '../../styles/LionGame.css';
import { initLionGame } from './lionLogic';

const MINING_RATE = 10; // 10 coins per second
const AUTO_SAVE_INTERVAL = 5000; // 5 seconds

const LionGame = () => {
  const mountRef = useRef(null);
  const { user, setUser } = useUser();
  const { 
    coinPoolBalance, 
    setCoinPoolBalance, 
    startGlobalLoading, 
    stopGlobalLoading,
    setError
  } = useAppContext();
  const navigate = useNavigate();
  const location = useLocation();
  const [isHolding, setIsHolding] = useState(false);
  const totalMinedRef = useRef(0);
  const gameRef = useRef(null);
  const animationFrameRef = useRef(null);
  const mousePosRef = useRef({ x: 0, y: 0 });

  const updateGameCoinPoolBalance = useCallback((balance) => {
    setCoinPoolBalance(Math.floor(balance));
  }, [setCoinPoolBalance]);

  const saveCoins = async () => {
    if (totalMinedRef.current > 0) {
      try {
        const finalCoinsMined = Math.floor(totalMinedRef.current);
        await updateHoldToEarn(0, finalCoinsMined);
        const claimResult = await claimCoins(finalCoinsMined);
        if (claimResult && claimResult.userUpdate) {
          setUser(prevUser => ({
            ...prevUser,
            coins: claimResult.userUpdate.coins
          }));
          setCoinPoolBalance(claimResult.newPoolBalance);
          totalMinedRef.current = 0;
        } else {
          console.error('Unexpected claim result:', claimResult);
          throw new Error('Unexpected claim result');
        }
      } catch (error) {
        console.error('Failed to save coins:', error);
        throw error;
      }
    }
  };

  const handleEndGame = useCallback(() => {
    if (gameRef.current) {
      gameRef.current.cleanup();
    }
    if (animationFrameRef.current) {
      cancelAnimationFrame(animationFrameRef.current);
    }
    if ('vibrate' in navigator) {
      navigator.vibrate(0);
    }

    saveCoins().catch(error => {
      console.error('Error saving coins during exit:', error);
    });

    navigate('/games', { replace: true });
  }, [navigate]);

  const mineCoins = useCallback((deltaTime) => {
    const minedAmount = MINING_RATE * deltaTime;
    totalMinedRef.current += minedAmount;
    setCoinPoolBalance(prev => {
      const newBalance = Math.max(0, prev - minedAmount);
      return newBalance;
    });
  }, [setCoinPoolBalance]);

  const gameLoop = useCallback(() => {
    if (gameRef.current) {
      const deltaTime = gameRef.current.getDeltaTime();
      const currentFanSpeed = gameRef.current.getFanSpeed();
      
      if (gameRef.current.getIsBlowing()) {
        mineCoins(deltaTime);
        if ('vibrate' in navigator) {
          navigator.vibrate([10, 10]);
        }
      } else {
        if ('vibrate' in navigator) {
          navigator.vibrate(0);
        }
      }

      // Update mouse position in the game
      if (gameRef.current.mousePos) {
        gameRef.current.mousePos.x = mousePosRef.current.x;
        gameRef.current.mousePos.y = mousePosRef.current.y;
      }
    }
    animationFrameRef.current = requestAnimationFrame(gameLoop);
  }, [mineCoins]);

  useEffect(() => {
    document.body.classList.add('lion-game-active');
    startGlobalLoading();

    const gameContainer = document.querySelector('.lion-game-container');

    // Disable context menu (right-click) for the game container only
    const disableContextMenu = (e) => e.preventDefault();
    gameContainer.addEventListener('contextmenu', disableContextMenu);

    // Disable long-press to select for the game container only
    const disableLongPressSelect = (e) => e.preventDefault();
    gameContainer.addEventListener('touchstart', disableLongPressSelect, { passive: false });

    const loadScript = (src) => {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = src;
        script.async = true;
        script.onload = resolve;
        script.onerror = reject;
        document.body.appendChild(script);
      });
    };

    const initGame = () => {
      const game = initLionGame(window.THREE);
      gameRef.current = game;

      game.init();
      game.createLights();
      game.createFloor();
      game.createLion();
      game.createFan();
      game.loop();

      animationFrameRef.current = requestAnimationFrame(gameLoop);
      stopGlobalLoading();
    };

    const loadScriptsAndInitGame = async () => {
      try {
        await loadScript('https://cdnjs.cloudflare.com/ajax/libs/three.js/r128/three.min.js');
        await loadScript('https://cdn.jsdelivr.net/npm/three@0.128.0/examples/js/controls/OrbitControls.js');
        await loadScript('https://cdnjs.cloudflare.com/ajax/libs/gsap/3.7.1/gsap.min.js');
        initGame();
      } catch (error) {
        console.error('Failed to load scripts:', error);
        stopGlobalLoading();
      }
    };

    loadScriptsAndInitGame();

    return () => {
      document.body.classList.remove('lion-game-active');
      saveCoins();
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
      if (gameRef.current) {
        gameRef.current.cleanup();
      }
      if ('vibrate' in navigator) {
        navigator.vibrate(0);
      }
      gameContainer.removeEventListener('contextmenu', disableContextMenu);
      gameContainer.removeEventListener('touchstart', disableLongPressSelect);
    };
  }, [gameLoop, startGlobalLoading, stopGlobalLoading]);

  useEffect(() => {
    const fetchInitialBalance = async () => {
      try {
        const balance = await fetchCoinPoolBalance();
        setCoinPoolBalance(balance);
      } catch (error) {
        console.error('Failed to fetch initial coin pool balance:', error);
      }
    };

    fetchInitialBalance();
    totalMinedRef.current = 0;
  }, [setCoinPoolBalance]);

  useEffect(() => {
    const autoSaveInterval = setInterval(saveCoins, AUTO_SAVE_INTERVAL);
    return () => clearInterval(autoSaveInterval);
  }, []);

  useEffect(() => {
    const handleLocationChange = () => {
      if (location.pathname !== '/lion-game') {
        saveCoins();
      }
    };

    return () => {
      handleLocationChange(); // Save coins on unmount
    };
  }, [location]);

  const handleStart = useCallback((e) => {
    e.preventDefault();
    setIsHolding(true);
    if (gameRef.current) {
      gameRef.current.setIsBlowing(true);
    }
  }, []);

  const handleEnd = useCallback((e) => {
    e.preventDefault();
    setIsHolding(false);
    if (gameRef.current) {
      gameRef.current.setIsBlowing(false);
    }
  }, []);

  const handleMouseMove = useCallback((e) => {
    if (mountRef.current) {
      const rect = mountRef.current.getBoundingClientRect();
      mousePosRef.current = {
        x: e.clientX - rect.left,
        y: e.clientY - rect.top
      };
    }
  }, []);

  return (
    <>
      <div className="game-header">
        <div className="coin-pool-balance">💰 {Math.floor(coinPoolBalance)}</div>
        <button className="exit-button" onClick={handleEndGame}>Exit</button>
      </div>
      <div className="lion-game-wrapper">
        <div className="game-content">
          <div className="game-instructions">
            Press and drag to make wind
          </div>
          <div 
            ref={mountRef} 
            className="lion-game-container"
            onMouseDown={handleStart}
            onMouseUp={handleEnd}
            onTouchStart={(e) => {
              e.preventDefault(); // Disable long press
              handleStart(e);
            }}
            onTouchEnd={handleEnd}
            onMouseMove={handleMouseMove}
            onTouchMove={(e) => {
              e.preventDefault(); // Disable long press
              const touch = e.touches[0];
              handleMouseMove({
                clientX: touch.clientX,
                clientY: touch.clientY
              });
            }}
          >
            <div id="world"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LionGame;