import React, { createContext, useState, useContext, useCallback } from 'react';

const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [games, setGames] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [leaderboard, setLeaderboard] = useState([]);
  const [wallet, setWallet] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isGlobalLoading, setIsGlobalLoading] = useState(false);
  const [error, setError] = useState(null);
  const [coinPoolBalance, setCoinPoolBalance] = useState(0);

  const startLoading = useCallback(() => setIsLoading(true), []);
  const stopLoading = useCallback(() => setIsLoading(false), []);
  const startGlobalLoading = useCallback(() => setIsGlobalLoading(true), []);
  const stopGlobalLoading = useCallback(() => setIsGlobalLoading(false), []);
  const clearError = useCallback(() => setError(null), []);

  const value = {
    games,
    setGames,
    tasks,
    setTasks,
    leaderboard,
    setLeaderboard,
    wallet,
    setWallet,
    isLoading,
    startLoading,
    stopLoading,
    isGlobalLoading,
    startGlobalLoading,
    stopGlobalLoading,
    error,
    setError,
    clearError,
    coinPoolBalance,
    setCoinPoolBalance
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (context === undefined) {
    throw new Error('useAppContext must be used within an AppProvider');
  }
  return context;
};