import React, { useState, useEffect, useCallback } from 'react';
import { FaWallet, FaPaperPlane, FaQrcode, FaLayerGroup, FaChevronLeft, FaCopy, FaExchangeAlt, FaSync, FaCheckCircle, FaTimesCircle, FaArrowUp, FaArrowDown } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { QRCodeSVG } from 'qrcode.react';
import Modal from 'react-modal';
import {
  getEdgewareBalance,
  sendEdgeware,
  stakeEdgeware,
  unstakeEdgeware,
  getEdgewareTransactions,
  getEdgewareValidators,
  estimateTotalFees,
  refreshEdgewareData,
  getTotalStakableBalance,
} from '../services/api';
import { useUser } from '../contexts/UserContext';
import '../styles/EdgewareWallet.css';

Modal.setAppElement('#root');

const TRANSACTIONS_PER_PAGE = 20;
const WASM_RESERVED_AMOUNT = 0.1;
const EVM_RESERVED_AMOUNT = 0.01;
const MIN_TRANSACTION_AMOUNT = 5;
const MIN_UNSTAKE_AMOUNT = 1;

const formatBalance = (balance) => parseFloat(balance).toFixed(5);

const EdgewareWallet = () => {
  const { user } = useUser();
  const [balance, setBalance] = useState({ total: '0', staked: '0', unlocked: '0', unlocking: '0' });
  const [address, setAddress] = useState({ wasm: '', evm: '' });
  const [transactions, setTransactions] = useState([]);
  const [stakingInfo, setStakingInfo] = useState(null);
  const [activeTab, setActiveTab] = useState('balance');
  const [sendAmount, setSendAmount] = useState('');
  const [sendRecipient, setSendRecipient] = useState('');
  const [sendAddressType, setSendAddressType] = useState('wasm');
  const [stakeAmount, setStakeAmount] = useState('');
  const [unstakeAmount, setUnstakeAmount] = useState('');
  const [selectedValidator, setSelectedValidator] = useState('');
  const [validators, setValidators] = useState([]);
  const [estimatedFee, setEstimatedFee] = useState('0');
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [totalStakableBalance, setTotalStakableBalance] = useState('0');
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showStakeConfirmation, setShowStakeConfirmation] = useState(false);
  const [showUnstakeConfirmation, setShowUnstakeConfirmation] = useState(false);
  const [transactionDetails, setTransactionDetails] = useState(null);
  const [stakeDetails, setStakeDetails] = useState(null);
  const [unstakeDetails, setUnstakeDetails] = useState(null);
  const [showResult, setShowResult] = useState(false);
  const [resultMessage, setResultMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);

  const fetchWalletData = async (refresh = true) => {
    setIsLoading(true);
    try {
      let data;
      if (refresh) {
        data = await refreshEdgewareData();
      } else {
        data = await getEdgewareBalance();
      }
      console.log('Fetched wallet data:', data);
      if (data) {
        if (data.balance) {
          setBalance({
            total: data.balance.total,
            staked: data.balance.staked,
            unlocked: data.balance.unlocked,
            unlocking: data.balance.unlocking
          });
        }
        if (data.transactions && data.transactions.transactions) {
          setTransactions(data.transactions.transactions);
          setHasMore(data.transactions.hasMore);
        }
        if (data.stakingInfo) {
          setStakingInfo(data.stakingInfo);
        }
        if (data.addresses) {
          console.log('Setting addresses:', data.addresses);
          setAddress(data.addresses);
        } else {
          console.log('No address data received');
        }
      }
      setPage(1);
    } catch (error) {
      console.error('Error fetching wallet data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchWalletData(true);
    fetchValidators();
    fetchTotalStakableBalance();
  }, []);

  useEffect(() => {
    if (sendAmount && sendRecipient && sendAddressType) {
      estimateTransactionFee();
    }
  }, [sendAmount, sendRecipient, sendAddressType]);

  useEffect(() => {
    console.log('Current address state:', address);
  }, [address]);

  useEffect(() => {
    console.log('Current sendAddressType:', sendAddressType);
  }, [sendAddressType]);

  const fetchMoreTransactions = useCallback(async () => {
    if (!hasMore || isLoading) return;
    setIsLoading(true);
    try {
      const response = await getEdgewareTransactions(page + 1);
      setTransactions(prevTransactions => [...prevTransactions, ...response.transactions]);
      setHasMore(response.hasMore);
      setPage(prevPage => prevPage + 1);
    } catch (error) {
      console.error('Error fetching more transactions:', error);
    } finally {
      setIsLoading(false);
    }
  }, [hasMore, isLoading, page]);

  const handleScroll = useCallback((e) => {
    const { scrollTop, clientHeight, scrollHeight } = e.currentTarget;
    if (scrollHeight - scrollTop <= clientHeight * 1.5) {
      fetchMoreTransactions();
    }
  }, [fetchMoreTransactions]);

  const fetchValidators = async () => {
    try {
      const validatorsData = await getEdgewareValidators();
      setValidators(validatorsData);
    } catch (error) {
      console.error('Error fetching validators:', error);
    }
  };

  const fetchTotalStakableBalance = async () => {
    try {
      const balance = await getTotalStakableBalance();
      setTotalStakableBalance(balance);
    } catch (error) {
      console.error('Error fetching total stakable balance:', error);
    }
  };

  const handleMaxAmount = () => {
    setSendAmount(formatBalance(balance.unlocked));
  };

  const estimateTransactionFee = async () => {
    try {
      if (!sendAmount || isNaN(parseFloat(sendAmount))) {
        setEstimatedFee('0');
        return;
      }
      const response = await estimateTotalFees('send', {
        amount: sendAmount,
        fromType: address.wasm ? 'wasm' : 'evm',
        toType: sendAddressType,
        toAddress: sendRecipient || '0x0000000000000000000000000000000000000000'
      });
      console.log('Fee estimation response:', response);
      if (typeof response !== 'number' && typeof response !== 'string') {
        console.error('Invalid fee response:', response);
        setEstimatedFee('0');
        return;
      }
      const fee = typeof response === 'number' ? response.toString() : response;
      setEstimatedFee(fee);
    } catch (error) {
      console.error('Error estimating fee:', error);
      setEstimatedFee('0');
    }
  };

  const handleSend = async (e) => {
    e.preventDefault();
    if (parseFloat(sendAmount) < MIN_TRANSACTION_AMOUNT) {
      setResultMessage(`Minimum send amount is ${MIN_TRANSACTION_AMOUNT} EDG`);
      setIsSuccess(false);
      setShowResult(true);
      return;
    }
    try {
      const response = await estimateTotalFees('send', {
        amount: sendAmount,
        fromType: address.wasm ? 'wasm' : 'evm',
        toType: sendAddressType,
        toAddress: sendRecipient
      });
      
      if (typeof response !== 'number' && typeof response !== 'string') {
        throw new Error('Invalid fee response');
      }

      const fee = typeof response === 'number' ? response.toString() : response;
      const amountToSendEDG = parseFloat(sendAmount);
      const feeEDG = parseFloat(fee);
      const actualSendAmountEDG = amountToSendEDG - feeEDG;

      if (actualSendAmountEDG <= 0) {
        throw new Error('Amount is too small to cover the transaction fee');
      }

      if (amountToSendEDG > parseFloat(balance.unlocked)) {
        throw new Error('Insufficient unlocked balance');
      }
      
      setTransactionDetails({
        amount: sendAmount,
        actualSendAmount: actualSendAmountEDG.toFixed(18),
        recipient: sendRecipient,
        addressType: sendAddressType,
        fee: fee
      });
      
      setShowConfirmation(true);
    } catch (error) {
      console.error('Error preparing transaction:', error);
      setResultMessage(`Failed to prepare transaction: ${error.message}`);
      setIsSuccess(false);
      setShowResult(true);
    }
  };

  const confirmSend = async () => {
    setIsLoading(true);
    try {
      await sendEdgeware(transactionDetails.actualSendAmount, transactionDetails.addressType, transactionDetails.recipient);
      setResultMessage('Transaction sent successfully!');
      setIsSuccess(true);
      console.log('Transaction successful, showing result screen');
    } catch (error) {
      console.error('Error sending transaction:', error);
      setResultMessage(`Failed to send transaction: ${error.message}`);
      setIsSuccess(false);
      console.log('Transaction failed, showing result screen');
    } finally {
      setIsLoading(false);
      setShowConfirmation(false);
      setShowResult(true);
      console.log('showResult set to true');
    }
  };

  const handleStake = async (e) => {
    e.preventDefault();
    if (parseFloat(stakeAmount) < MIN_TRANSACTION_AMOUNT) {
      setResultMessage(`Minimum staking amount is ${MIN_TRANSACTION_AMOUNT} EDG`);
      setIsSuccess(false);
      setShowResult(true);
      return;
    }
    try {
      const fee = await estimateTotalFees('stake', {
        amount: stakeAmount,
        validatorAddress: selectedValidator
      });
      
      setStakeDetails({
        amount: stakeAmount,
        validator: validators.find(v => v.address === selectedValidator).name,
        fee: fee.toString()
      });
      
      setShowStakeConfirmation(true);
    } catch (error) {
      console.error('Error preparing stake:', error);
      setResultMessage(`Failed to prepare stake: ${error.message}`);
      setIsSuccess(false);
      setShowResult(true);
    }
  };

  const confirmStake = async () => {
    setIsLoading(true);
    try {
      await stakeEdgeware(stakeDetails.amount, selectedValidator);
      setResultMessage('Tokens staked successfully!');
      setIsSuccess(true);
      console.log('Staking successful, showing result screen');
    } catch (error) {
      console.error('Error staking tokens:', error);
      setResultMessage('Failed to stake tokens. Please try again.');
      setIsSuccess(false);
      console.log('Staking failed, showing result screen');
    } finally {
      setIsLoading(false);
      setShowStakeConfirmation(false);
      setShowResult(true);
      console.log('showResult set to true');
    }
  };

  const handleUnstake = async (e) => {
    e.preventDefault();
    if (parseFloat(unstakeAmount) < MIN_UNSTAKE_AMOUNT) {
      setResultMessage(`Minimum unstaking amount is ${MIN_UNSTAKE_AMOUNT} EDG`);
      setIsSuccess(false);
      setShowResult(true);
      return;
    }
    try {
      const fee = await estimateTotalFees('unstake', {
        amount: unstakeAmount
      });
      
      setUnstakeDetails({
        amount: unstakeAmount,
        fee: fee.toString()
      });
      
      setShowUnstakeConfirmation(true);
    } catch (error) {
      console.error('Error preparing unstake:', error);
      setResultMessage(`Failed to prepare unstake: ${error.message}`);
      setIsSuccess(false);
      setShowResult(true);
    }
  };

  const confirmUnstake = async () => {
    setIsLoading(true);
    try {
      await unstakeEdgeware(unstakeDetails.amount);
      setResultMessage('Tokens unstaked successfully! They will be automatically withdrawn when available.');
      setIsSuccess(true);
      console.log('Unstaking successful, showing result screen');
    } catch (error) {
      console.error('Error unstaking tokens:', error);
      setResultMessage('Failed to unstake tokens. Please try again.');
      setIsSuccess(false);
      console.log('Unstaking failed, showing result screen');
    } finally {
      setIsLoading(false);
      setShowUnstakeConfirmation(false);
      setShowResult(true);
      console.log('showResult set to true');
    }
  };

  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      console.log('Address copied to clipboard');
    } catch (err) {
      console.warn('Clipboard API not available, falling back to execCommand', err);
      
      const textArea = document.createElement("textarea");
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        document.execCommand('copy');
        console.log('Address copied to clipboard using execCommand');
      } catch (err) {
        console.error('Fallback: Oops, unable to copy', err);
      }

      document.body.removeChild(textArea);
    }
  };

  const handleRefresh = async () => {
    await fetchWalletData(true);
    await fetchValidators();
    await fetchTotalStakableBalance();
  };

  const closeResultModal = () => {
    setShowResult(false);
    handleRefresh();
    console.log('Result modal closed, refreshing wallet data');
  };

  const getTransactionType = (type) => {
    switch (type) {
      case 'send':
      case 'wasm_to_evm':
      case 'evm_to_wasm':
      case 'evm_withdraw_to_wasm':
      case 'evm_to_converted_address':
        return 'Withdraw';
      case 'receive':
        return 'Deposit';
      case 'stake':
        return 'Stake';
      case 'unstake':
        return 'Unstake';
      default:
        return type;
    }
  };

  return (
    <div className="edgeware-wallet-page">
      <header className="wallet-header">
        <Link to="/wallet" className="back-button">
          <FaChevronLeft />
        </Link>
        <h1><FaWallet /> Edgeware Wallet</h1>
        <button onClick={handleRefresh} className="refresh-button" disabled={isLoading}>
          <FaSync className={isLoading ? 'spin' : ''} />
        </button>
      </header>

      {isLoading && <div className="loading">Loading...</div>}

      <div className="wallet-card">
        <div className="wallet-balance">
          <h2>Balance</h2>
          <p className="balance-amount">Total: {formatBalance(balance.total)} EDG</p>
          <p>Staked: {formatBalance(parseFloat(balance.staked) + parseFloat(balance.unlocking))} EDG</p>
          <p>Unlocked: {formatBalance(balance.unlocked)} EDG</p>
        </div>
      </div>

      <div className="wallet-actions">
        <button className={`action-button ${activeTab === 'send' ? 'active' : ''}`} onClick={() => setActiveTab('send')}>
          <FaPaperPlane />
          <span>Send</span>
        </button>
        <button className={`action-button ${activeTab === 'receive' ? 'active' : ''}`} onClick={() => setActiveTab('receive')}>
          <FaQrcode />
          <span>Receive</span>
        </button>
        <button className={`action-button ${activeTab === 'stake' ? 'active' : ''}`} onClick={() => setActiveTab('stake')}>
          <FaLayerGroup />
          <span>Stake</span>
        </button>
      </div>

      {activeTab === 'send' && (
        <div className="send-form">
          <h3>Send EDG</h3>
          <form onSubmit={handleSend}>
            <div className="input-with-max">
              <input
                type="text"
                value={sendAmount}
                onChange={(e) => setSendAmount(e.target.value)}
                placeholder="Amount"
              />
              <button type="button" onClick={handleMaxAmount}>Max</button>
            </div>
            <p>Available: {formatBalance(balance.unlocked)} EDG</p>
            <div className="address-type-selector">
              <label>
                <input
                  type="radio"
                  value="wasm"
                  checked={sendAddressType === 'wasm'}
                  onChange={() => setSendAddressType('wasm')}
                />
                WASM
              </label>
              <label>
                <input
                  type="radio"
                  value="evm"
                  checked={sendAddressType === 'evm'}
                  onChange={() => setSendAddressType('evm')}
                />
                EVM
              </label>
            </div>
            <input
              type="text"
              value={sendRecipient}
              onChange={(e) => setSendRecipient(e.target.value)}
              placeholder="Recipient Address"
            />
            {estimatedFee !== '0' && <p>Estimated Fee: {formatBalance(estimatedFee)} EDG</p>}
            <button 
              type="submit" 
              disabled={
                !sendAmount || 
                !sendRecipient || 
                parseFloat(sendAmount) > parseFloat(balance.unlocked) || 
                isLoading
              }
            >
              {isLoading ? 'Preparing...' : 'Send'}
            </button>
          </form>
        </div>
      )}

      {activeTab === 'receive' && (
        <div className="receive-info">
          <h3>Receive EDG</h3>
          <div className="address-tabs">
            <button className={sendAddressType === 'wasm' ? 'active' : ''} onClick={() => setSendAddressType('wasm')}>WASM</button>
            <button className={sendAddressType === 'evm' ? 'active' : ''} onClick={() => setSendAddressType('evm')}>EVM</button>
          </div>
          <div className="qr-code-container">
            <QRCodeSVG value={address[sendAddressType] || ''} size={200} />
          </div>
          <div className="address-container">
            <p className="address-label">Your {sendAddressType.toUpperCase()} Address:</p>
            <p className="address">
              {address[sendAddressType] || `Address not available (${sendAddressType})`}
              {address[sendAddressType] && (
                <button className="copy-button" onClick={() => copyToClipboard(address[sendAddressType])}>
                  <FaCopy />
                </button>
              )}
            </p>
          </div>
        </div>
      )}

      {activeTab === 'stake' && (
        <div className="stake-form">
          <h3>Stake EDG</h3>
          <form onSubmit={handleStake}>
            <div className="input-with-max">
              <input
                type="text"
                value={stakeAmount}
                onChange={(e) => setStakeAmount(e.target.value)}
                placeholder={`Amount (minimum ${MIN_TRANSACTION_AMOUNT} EDG)`}
              />
              <button type="button" onClick={() => setStakeAmount(formatBalance(balance.unlocked))}>Max</button>
            </div>
            <p>Available for Staking: {formatBalance(balance.unlocked)} EDG</p>
            <select
              value={selectedValidator}
              onChange={(e) => setSelectedValidator(e.target.value)}
            >
              <option value="">Select Validator</option>
              {validators.map((validator) => (
                <option key={validator.address} value={validator.address}>
                  {validator.name} - Commission: {validator.commission}%
                </option>
              ))}
            </select>
            <button type="submit" disabled={parseFloat(stakeAmount) < MIN_TRANSACTION_AMOUNT || !selectedValidator || isLoading}>
              {isLoading ? 'Preparing...' : 'Stake'}
            </button>
          </form>

          <h4>Staking Info</h4>
{stakingInfo ? (
  <div>
    <p>Total Staked: {formatBalance(stakingInfo.total)} EDG</p>
    <p>Active Stake: {formatBalance(stakingInfo.active)} EDG</p>
    <p>Unlocking: {formatBalance(stakingInfo.unlocking.reduce((sum, item) => sum + parseFloat(item.value), 0))} EDG</p>
    {stakingInfo.unlocking.length > 0 && (
      <div>
        <p className="info-text">Unlocking details:</p>
        <ul>
          {stakingInfo.unlocking.map((unlock, index) => (
            <li key={index}>
              {formatBalance(unlock.value)} EDG, unlocks at era {unlock.era}
            </li>
          ))}
        </ul>
        <p className="info-text">Unlocking funds will be automatically withdrawn when available.</p>
      </div>
    )}
  </div>
) : (
  <p>No staking information available</p>
)}

<h4>Unstake EDG</h4>
<form onSubmit={handleUnstake}>
  <div className="input-with-max">
    <input
      type="text"
      value={unstakeAmount}
      onChange={(e) => setUnstakeAmount(e.target.value)}
      placeholder={`Amount to unstake (minimum ${MIN_UNSTAKE_AMOUNT} EDG)`}
    />
    <button 
      type="button" 
      onClick={() => setUnstakeAmount(formatBalance(stakingInfo?.active || '0'))}
    >
      Max
    </button>
  </div>
  <p>Available for Unstaking: {formatBalance(stakingInfo?.active || '0')} EDG</p>
  <button 
    type="submit" 
    disabled={
      parseFloat(unstakeAmount) < MIN_UNSTAKE_AMOUNT || 
      parseFloat(unstakeAmount) > parseFloat(stakingInfo?.active || '0') || 
      isLoading
    }
  >
    {isLoading ? 'Preparing...' : 'Unstake'}
  </button>
</form>
        </div>
      )}

      <div className="transaction-list" onScroll={handleScroll}>
        <h3>Recent Transactions</h3>
        {transactions.map((tx, index) => (
          <div key={index} className="transaction-item">
            <div className="transaction-left">
              <div className="transaction-icon">
                {getTransactionType(tx.transaction_type) === 'Withdraw' ? <FaArrowUp /> : <FaArrowDown />}
              </div>
              <div className="transaction-info">
                <span className="transaction-type">
                  {getTransactionType(tx.transaction_type)}
                </span>
                <span className="transaction-amount">{formatBalance(tx.amount)} EDG</span>
              </div>
            </div>
            <div className="transaction-right">
              <span className={`transaction-status ${tx.status}`}>{tx.status}</span>
              <span className="transaction-time">{new Date(tx.timestamp).toLocaleString()}</span>
              {tx.explorer_link && (
                <a 
                  href={tx.chain_type === 'wasm' ? `https://edgscan.ink/#/extrinsics/${tx.extrinsic_number}` : tx.explorer_link} 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="explorer-link"
                >
                  View in Explorer
                </a>
              )}
            </div>
          </div>
        ))}
        {isLoading && <div className="loading">Loading more transactions...</div>}
        {!hasMore && <div className="no-more-transactions">No more transactions</div>}
      </div>

      <Modal
        isOpen={showConfirmation}
        onRequestClose={() => setShowConfirmation(false)}
        contentLabel="Confirm Transaction"
        className="confirmation-modal"
        overlayClassName="confirmation-modal-overlay"
      >
        <h2>Confirm Transaction</h2>
        {transactionDetails && (
          <div>
            <p>Amount: {formatBalance(transactionDetails.amount)} EDG</p>
            <p>Recipient: {transactionDetails.recipient}</p>
            <p>Chain Type: {transactionDetails.addressType.toUpperCase()}</p>
            <p>Gas Fee: {formatBalance(transactionDetails.fee)} EDG</p>
            <p>You will send: {formatBalance(transactionDetails.amount)} EDG</p>
            <p>Recipient will receive: {formatBalance(transactionDetails.actualSendAmount)} EDG</p>
          </div>
        )}
        <div className="confirmation-buttons">
          <button onClick={confirmSend}>Confirm</button>
          <button onClick={() => setShowConfirmation(false)}>Cancel</button></div>
      </Modal>

      <Modal
        isOpen={showStakeConfirmation}
        onRequestClose={() => setShowStakeConfirmation(false)}
        contentLabel="Confirm Staking"
        className="confirmation-modal"
        overlayClassName="confirmation-modal-overlay"
      >
        <h2>Confirm Staking</h2>
        {stakeDetails && (
          <div>
            <p>Amount to Stake: {formatBalance(stakeDetails.amount)} EDG</p>
            <p>Selected Validator: {stakeDetails.validator}</p>
            <p>Estimated Fee: {formatBalance(stakeDetails.fee)} EDG</p>
          </div>
        )}
        <div className="confirmation-buttons">
          <button onClick={confirmStake}>Confirm</button>
          <button onClick={() => setShowStakeConfirmation(false)}>Cancel</button>
        </div>
      </Modal>

      <Modal
        isOpen={showUnstakeConfirmation}
        onRequestClose={() => setShowUnstakeConfirmation(false)}
        contentLabel="Confirm Unstaking"
        className="confirmation-modal"
        overlayClassName="confirmation-modal-overlay"
      >
        <h2>Confirm Unstaking</h2>
        {unstakeDetails && (
          <div>
            <p>Amount to Unstake: {formatBalance(unstakeDetails.amount)} EDG</p>
            <p>Estimated Fee: {formatBalance(unstakeDetails.fee)} EDG</p>
            <p className="warning">Warning: It will take 56 ERAS to withdraw funds from staking after unstaking. Funds will be automatically withdrawn when available.</p>
          </div>
        )}
        <div className="confirmation-buttons">
          <button onClick={confirmUnstake}>Confirm</button>
          <button onClick={() => setShowUnstakeConfirmation(false)}>Cancel</button>
        </div>
      </Modal>

      <Modal
        isOpen={showResult}
        onRequestClose={closeResultModal}
        contentLabel="Transaction Result"
        className="result-modal"
        overlayClassName="result-modal-overlay"
      >
        <div className={`result-content ${isSuccess ? 'success' : 'error'}`}>
          {isSuccess ? (
            <FaCheckCircle className="result-icon success" />
          ) : (
            <FaTimesCircle className="result-icon error" />
          )}
          <h2>{isSuccess ? 'Success' : 'Error'}</h2>
          <p>{resultMessage}</p>
          <button onClick={closeResultModal}>Back to Wallet</button>
        </div>
      </Modal>
    </div>
  );
};

export default EdgewareWallet;