import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { getUserStats, getReferredFriends, getLoginStreak, getEarningsChart, checkDailyBonus, fetchAndUpdateProfilePhoto, getDailyEarnings } from '../services/api';
import { useUser } from '../contexts/UserContext';
import { useAppContext } from '../contexts/AppContext';
import ShareDialog from '../components/Layout/ShareDialog';
import DailyBonusPopup from '../components/Layout/DailyBonusPopup';
import { FaCoins, FaUsers, FaCopy, FaSyncAlt } from 'react-icons/fa';
import { GoSmiley } from "react-icons/go";
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler } from 'chart.js';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import '../styles/Home.css';
import api from '../services/api';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler);

const Home = () => {
  const [userStats, setUserStats] = useState(null);
  const [referredFriends, setReferredFriends] = useState([]);
  const [isShareDialogOpen, setIsShareDialogOpen] = useState(false);
  const [profilePhotoUrl, setProfilePhotoUrl] = useState(null);
  const [isPremiumUser, setIsPremiumUser] = useState(false);
  const [showBonusPopup, setShowBonusPopup] = useState(false);
  const [loginStreak, setLoginStreak] = useState([]);
  const [earningsChart, setEarningsChart] = useState(null);
  const [copiedWallet, setCopiedWallet] = useState(false);
  const [dailyEarnings, setDailyEarnings] = useState([]);
  const [averageEarnings, setAverageEarnings] = useState(0);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { user, updateUser } = useUser();
  const { coinPoolBalance, setCoinPoolBalance } = useAppContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (window.Telegram?.WebApp) {
      window.Telegram.WebApp.setHeaderColor('#000000');
    }
  }, []);

  const getProfilePhoto = async (userId) => {
    let isPremium = false;
    if (window.Telegram?.WebApp?.initDataUnsafe?.user) {
      const telegramUser = window.Telegram.WebApp.initDataUnsafe.user;
      isPremium = telegramUser.is_premium || false;
      const photoResult = await fetchAndUpdateProfilePhoto(telegramUser.id);
      if (photoResult.photoUrl) {
        return { photoUrl: photoResult.photoUrl, isPremium };
      }
    }
    
    try {
      const response = await api.get(`/users/profile-picture/${userId}`);
      return { photoUrl: response.data.photoUrl, isPremium };
    } catch (error) {
      console.error('Error fetching profile photo from backend:', error);
      return { photoUrl: null, isPremium };
    }
  };

  const fetchData = useCallback(async () => {
    if (!user) return;

    setIsLoading(true);
    try {
      const [stats, friends, streakData, chartData, bonusCheck, dailyEarningsData] = await Promise.all([
        getUserStats(),
        getReferredFriends(),
        getLoginStreak(),
        getEarningsChart(),
        checkDailyBonus(),
        getDailyEarnings()
      ]);

      setUserStats(stats);
      setReferredFriends(friends);
      setLoginStreak(streakData.lastSevenDays);
      setEarningsChart(chartData);
      setShowBonusPopup(bonusCheck.eligible);
      setDailyEarnings(dailyEarningsData.dailyEarnings);
      setAverageEarnings(dailyEarningsData.averageEarnings);

      const { photoUrl, isPremium } = await getProfilePhoto(user.id);
      setProfilePhotoUrl(photoUrl);
      setIsPremiumUser(isPremium);

    } catch (error) {
      console.error('Error fetching data:', error);
      setError('Failed to load data. Please try again.');
    } finally {
      setIsLoading(false);
    }
  }, [user]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleInvite = () => {
    setIsShareDialogOpen(true);
  };

  const handleCloseShareDialog = () => {
    setIsShareDialogOpen(false);
  };

  const handleCopyWallet = () => {
    setCopiedWallet(true);
    setTimeout(() => setCopiedWallet(false), 2000);
  };

  const inviteLink = `https://t.me/EdgeverseBot/app?startapp=ref_${userStats?.referral_code}`;
  const inviteMessage = `Join me on Edgeverse! Use my referral link: ${inviteLink}`;

  const formatNumber = (num) => {
    if (num >= 1000000) return (num / 1000000).toFixed(1) + 'M';
    if (num >= 1000) return (num / 1000).toFixed(1) + 'K';
    return num.toString();
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${String(date.getDate()).padStart(2, '0')}/${String(date.getMonth() + 1).padStart(2, '0')}`;
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          color: '#a0a0a0',
          font: {
            size: 10,
          },
        },
      },
      y: {
        grid: {
          color: 'rgba(160, 160, 160, 0.1)',
        },
        ticks: {
          color: '#a0a0a0',
          font: {
            size: 10,
          },
          callback: (value) => formatNumber(value),
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        titleColor: '#80ff00',
        bodyColor: '#fff',
        titleFont: {
          size: 12,
          weight: 'bold',
        },
        bodyFont: {
          size: 10,
        },
        padding: 8,
        displayColors: false,
        callbacks: {
          label: function(context) {
            let label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              label += formatNumber(context.parsed.y) + ' coins';
            }
            return label;
          }
        }
      },
    },
    onClick: (event, elements) => {
      if (elements.length > 0) {
        const index = elements[0].index;
        const date = dailyEarnings[index].date;
        const coins = dailyEarnings[index].coins_earned;
        // You can add functionality here if needed
      }
    },
  };

  const chartData = {
    labels: dailyEarnings.map((day) => formatDate(new Date(day.date))),
    datasets: [
      {
        data: dailyEarnings.map((day) => day.coins_earned),
        borderColor: '#80ff00',
        backgroundColor: 'rgba(128, 255, 0, 0.2)',
        tension: 0.4,
        fill: true,
        pointBackgroundColor: '#fff',
        pointBorderColor: '#80ff00',
        pointBorderWidth: 1,
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: '#80ff00',
        pointRadius: 4,
        pointHoverRadius: 6,
      },
    ],
  };

  if (isLoading) {
    return (
      <div className="loading-screen">
        <svg 
          className="loading-icon" 
          x="0px" 
          y="0px" 
          viewBox="0 0 37 37" 
          height="37" 
          width="37" 
          preserveAspectRatio="xMidYMid meet"
        >
          <path 
            className="track" 
            fill="none" 
            strokeWidth="5" 
            pathLength="100" 
            d="M0.37 18.5 C0.37 5.772 5.772 0.37 18.5 0.37 S36.63 5.772 36.63 18.5 S31.228 36.63 18.5 36.63 S0.37 31.228 0.37 18.5">
          </path>
          <path 
            className="car" 
            fill="none" 
            strokeWidth="5" 
            pathLength="100" 
            d="M0.37 18.5 C0.37 5.772 5.772 0.37 18.5 0.37 S36.63 5.772 36.63 18.5 S31.228 36.63 18.5 36.63 S0.37 31.228 0.37 18.5">
          </path>
        </svg>
      </div>
    );
  }

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  if (!userStats) {
    return <div className="home loading">Loading...</div>;
  }

  return (
    <div className="home">
      {showBonusPopup && (
        <DailyBonusPopup
          onClose={() => setShowBonusPopup(false)}
          onClaim={(newCoins, newPasses) => {
            setUserStats(prevStats => ({
              ...prevStats,
              coins: newCoins,
              game_passes: newPasses,
            }));
          }}
        />
      )}

      <div className="user-balance-card">
        <div className="user-info">
          <div className="profile-section">
            <div className="profile-photo-container">
              <img src={profilePhotoUrl || '/default-avatar.png'} alt="Profile" className="profile-picture" />
              {isPremiumUser && (
                <div className="premium-icon">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="8" cy="8" r="8" fill="#FFD700"/>
                    <path d="M8 3L9.79611 6.52786H13.6085L10.4062 8.69427L11.7023 12.2221L8 10.3L4.29772 12.2221L5.59383 8.69427L2.39155 6.52786H6.20389L8 3Z" fill="#1E1E1E"/>
                  </svg>
                </div>
              )}
            </div>
            <div className="user-details">
              <span className="welcome">Welcome!</span>
              <span className="username">{user?.username}</span>
            </div>
          </div>
          <div className="balance-info">
            <span className="balance-label">Available Balance</span>
            <div className="balance-value-container">
              <FaCoins className="coin-icon" />
              <span className="balance-value">{formatNumber(userStats.coins)}</span>
            </div>
          </div>
        </div>
      </div>

      <p className="average-earnings">You earned an average of {formatNumber(averageEarnings)} coins this week</p>

      <div className="card-earnings-card">
        <div className="chart-container">
          <Line data={chartData} options={chartOptions} />
        </div>
      </div>

      <div className="card-streak-card">
        <h4>Weekly Streak</h4>
        <div className="streak-calendar">
          {loginStreak.map((day, index) => (
            <div key={index} className={`days ${day.loggedIn ? 'completed' : ''}`}>
              {day.day[0]}
            </div>
          ))}
        </div>
        <div className="streak-calendar">
          {loginStreak.map((day, index) => (
            <div key={index} className={`streak-day ${day.loggedIn ? 'completed' : 'missed'}`}>
              <GoSmiley size={24} />
            </div>
          ))}
        </div>
      </div>

      <div className="card-friends-card">
        <h3>Friends</h3>
        <div className="friends-list-container">
          <div className="friends-list">
            {referredFriends.map((friend) => (
              <div key={friend.id} className="friend-item">
                <img src={friend.profile_picture_url || '/default-avatar.png'} alt={friend.username} className="friend-avatar" />
                <span className="friend-coins">{formatNumber(friend.coins)}</span>
              </div>
            ))}
          </div>
        </div>
        <button className="invite-button" onClick={handleInvite}>
          Invite Now
        </button>
      </div>

      <ShareDialog
        isOpen={isShareDialogOpen}
        onClose={handleCloseShareDialog}
        inviteMessage={inviteMessage}
        inviteLink={inviteLink}
      />
    </div>
  );
};

export default Home;