import React, { createContext, useState, useEffect, useContext, useRef, useCallback } from 'react';
import { initializeUser, setTelegramInitData, getUserStats, fetchAndUpdateProfilePhoto } from '../services/api';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [profilePhotoUrl, setProfilePhotoUrl] = useState(null);
  const updateIntervalRef = useRef(null);

  const initUser = useCallback(async () => {
    try {
      const tg = window.Telegram.WebApp;
      if (tg.initDataUnsafe && tg.initDataUnsafe.user) {
        setTelegramInitData(tg.initData);
        
        const startParam = new URLSearchParams(window.location.search).get('tgWebAppStartParam');
        const referralCode = startParam && startParam.startsWith('ref_') ? startParam.slice(4) : null;

        const userData = {
          telegramInitData: tg.initData,
          telegram_id: tg.initDataUnsafe.user.id.toString(),
          referral_code: referralCode
        };

        const response = await initializeUser(userData);
        if (response && response.user) {
          setUser(response.user);
          if (response.token) {
            localStorage.setItem('authToken', response.token);
          }

          // Fetch and update profile photo
          const photoResult = await fetchAndUpdateProfilePhoto(tg.initDataUnsafe.user.id);
          setProfilePhotoUrl(photoResult.photoUrl);
        } else {
          throw new Error('Invalid user data received from server');
        }
      } else {
        throw new Error('Telegram WebApp data is not available');
      }
    } catch (error) {
      console.error('Failed to initialize user:', error);
      setError(error.message);
      setUser(null); // Ensure user is set to null on error
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    initUser();
  }, [initUser]);

  const updateUser = useCallback(async (newData) => {
    if (user && user.id) {
      try {
        if (newData) {
          setUser(prevUser => ({ ...prevUser, ...newData }));
        } else {
          const updatedStats = await getUserStats();
          setUser(prevUser => ({ ...prevUser, ...updatedStats }));
        }
      } catch (error) {
        console.error('Failed to update user stats:', error);
      }
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      updateIntervalRef.current = setInterval(() => updateUser(), 60000);
    }

    return () => {
      if (updateIntervalRef.current) {
        clearInterval(updateIntervalRef.current);
      }
    };
  }, [user, updateUser]);

  const logout = useCallback(() => {
    setUser(null);
    setProfilePhotoUrl(null);
    localStorage.removeItem('authToken');
    if (updateIntervalRef.current) {
      clearInterval(updateIntervalRef.current);
    }
  }, []);

  const value = {
    user,
    setUser,
    loading,
    logout,
    error,
    updateUser,
    profilePhotoUrl,
    setProfilePhotoUrl
  };

  return (
    <UserContext.Provider value={value}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};