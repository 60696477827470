import React, { useState, useEffect, useCallback } from 'react';
import { getLeaderboard } from '../services/api';
import { useUser } from '../contexts/UserContext';
import { FaMedal, FaCoins } from 'react-icons/fa';
import '../styles/Leaderboard.css';

const Leaderboard = () => {
  const [leaderboard, setLeaderboard] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { user, updateUser } = useUser();

  useEffect(() => {
    if (window.Telegram?.WebApp) {
      window.Telegram.WebApp.setHeaderColor('#000000');
    }
  }, []);

  const fetchLeaderboardData = useCallback(async () => {
    try {
      console.log('Fetching leaderboard data...');
      const data = await getLeaderboard();
      console.log('Leaderboard data received:', data);
      setLeaderboard(data);
      setLoading(false);
      updateUser();
    } catch (err) {
      console.error('Failed to fetch leaderboard:', err);
      setError('Failed to load leaderboard. Please try again.');
      setLoading(false);
    }
  }, [updateUser]);

  useEffect(() => {
    fetchLeaderboardData();
  }, [fetchLeaderboardData]);

  const getMedalIcon = (rank) => {
    switch(rank) {
      case 1: return <FaMedal className="gold" />;
      case 2: return <FaMedal className="silver" />;
      case 3: return <FaMedal className="bronze" />;
      default: return `#${rank}`;
    }
  };

  const getInitials = (username) => {
    return (username || '').substring(0, 2).toUpperCase();
  };

  const getRandomColor = (username) => {
    const colors = ['#FF6B6B', '#4ECDC4', '#45B7D1', '#FFA07A', '#98D8C8', '#F06292', '#AED581', '#7986CB', '#4DB6AC', '#FFD54F'];
    const index = (username || '').charCodeAt(0) % colors.length;
    return colors[index];
  };

  const renderLeaderboardItem = (entry, index) => {
    if (!entry) return null;
    return (
      <div key={entry.id} className="leaderboard-item">
        <div className="user-avatar" style={{backgroundColor: getRandomColor(entry.username)}}>
          {getInitials(entry.username)}
        </div>
        <div className="user-info">
          <div className="username">{entry.username || 'Unknown User'}</div>
          <div className="coins">
            <FaCoins className="icon" /> {entry.coins.toLocaleString()} Coins
          </div>
        </div>
        <div className={`rank ${index === 0 ? 'gold' : index === 1 ? 'silver' : index === 2 ? 'bronze' : ''}`}>
          {getMedalIcon(index + 1)}
        </div>
      </div>
    );
  };

  if (loading) {
    return (
      <div className="loading-screen">
        <svg 
          className="loading-icon" 
          x="0px" 
          y="0px" 
          viewBox="0 0 37 37" 
          height="37" 
          width="37" 
          preserveAspectRatio="xMidYMid meet"
        >
          <path 
            className="track" 
            fill="none" 
            strokeWidth="5" 
            pathLength="100" 
            d="M0.37 18.5 C0.37 5.772 5.772 0.37 18.5 0.37 S36.63 5.772 36.63 18.5 S31.228 36.63 18.5 36.63 S0.37 31.228 0.37 18.5">
          </path>
          <path 
            className="car" 
            fill="none" 
            strokeWidth="5" 
            pathLength="100" 
            d="M0.37 18.5 C0.37 5.772 5.772 0.37 18.5 0.37 S36.63 5.772 36.63 18.5 S31.228 36.63 18.5 36.63 S0.37 31.228 0.37 18.5">
          </path>
        </svg>
      </div>
    );
  }

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  const userRank = user ? leaderboard.findIndex(entry => entry.id === user.id) + 1 : -1;

  return (
    <div className="leaderboard">
      {user && userRank > 0 && (
        <div className="leaderboard-section">
          <h2>Your Rank</h2>
          {renderLeaderboardItem(user, userRank - 1)}
        </div>
      )}
      <div className="leaderboard-section">
        <h2>Top 100</h2>
        <div className="leaderboard-list">
          {leaderboard.slice(0, 100).map((entry, index) => renderLeaderboardItem(entry, index))}
        </div>
      </div>
    </div>
  );
};

export default Leaderboard;