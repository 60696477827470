import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { checkPaymentStatus, getPurchaseDetails } from '../../services/api';

const Checkout = () => {
  const { purchaseId } = useParams();
  const navigate = useNavigate();
  const [status, setStatus] = useState('pending');
  const [error, setError] = useState(null);
  const [purchase, setPurchase] = useState(null);

  useEffect(() => {
    const fetchPurchase = async () => {
      try {
        const purchaseData = await getPurchaseDetails(purchaseId);
        setPurchase(purchaseData);
      } catch (err) {
        console.error('Error fetching purchase details:', err);
        setError('Failed to load purchase details. Please try again.');
      }
    };

    fetchPurchase();
  }, [purchaseId]);

  useEffect(() => {
    if (!purchase) return;

    const checkStatus = async () => {
      try {
        const result = await checkPaymentStatus(purchaseId);
        console.log('Payment status:', result);
        setStatus(result.status);
        
        if (result.oxapay_status === 'Paid' || result.oxapay_status === 'Confirming' || result.status === 'payment_confirmed') {
          navigate('/payment-processing', { state: { purchaseId, nftName: purchase.nfts?.name } });
        } else if (result.status === 'failed' || result.status === 'cancelled') {
          navigate('/payment-cancelled');
        }
      } catch (err) {
        console.error('Error checking payment status:', err);
        setError('Failed to check payment status. Please contact support.');
      }
    };

    const intervalId = setInterval(checkStatus, 5000); // Check every 5 seconds

    return () => clearInterval(intervalId);
  }, [purchase, purchaseId, navigate]);

  const handleCheckStatus = () => {
    navigate('/payment-processing', { state: { purchaseId, nftName: purchase?.nfts?.name } });
  };

  if (error) {
    return (
      <div className="checkout-error">
        <h2>Error</h2>
        <p>{error}</p>
        <button onClick={() => navigate('/nfts')}>Return to NFTs</button>
      </div>
    );
  }

  if (!purchase) {
    return <div className="checkout-loading">Loading... Please wait.</div>;
  }

  return (
    <div className="checkout-container">
      <h2>Processing Payment</h2>
      <div className="purchase-details">
        <p><strong>NFT:</strong> {purchase.nfts?.name || 'NFT'}</p>
        <p><strong>Price:</strong> ${purchase.amount_usd}</p>
      </div>
      <div className="payment-status">
        <p><strong>Payment Status:</strong> {status}</p>
      </div>
      <p>Please complete the payment on the Oxapay payment page. This page will automatically update once the payment is processed.</p>
      <button onClick={handleCheckStatus}>Check Status</button>
    </div>
  );
};

export default Checkout;