import React, { useEffect } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { checkPaymentStatus } from '../../services/api';

const PaymentSuccess = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { trackId, nftName, amount } = location.state || {};

  useEffect(() => {
    if (!trackId) {
      // If we don't have trackId in the state, check the URL parameters
      const params = new URLSearchParams(location.search);
      const urlTrackId = params.get('trackId');
      const success = params.get('success');
      const status = params.get('status');

      if (urlTrackId && success === '1' && status === '1') {
        // Verify the payment status with the backend
        checkPaymentStatus(urlTrackId).then(result => {
          if (result.status !== 'completed' && result.status !== 'success' && result.status !== 'finished') {
            navigate('/payment-cancelled');
          }
        }).catch(error => {
          console.error('Error verifying payment:', error);
          navigate('/payment-cancelled');
        });
      } else {
        navigate('/nfts');
      }
    }
  }, [trackId, location, navigate]);

  return (
    <div className="payment-success">
      <h2>Payment Successful!</h2>
      <p>Thank you for your purchase.</p>
      {trackId && <p>Transaction ID: {trackId}</p>}
      {nftName && <p>NFT: {nftName}</p>}
      {amount && <p>Amount: ${amount}</p>}
      <Link to="/nfts">Return to NFTs</Link>
    </div>
  );
};

export default PaymentSuccess;