import React from 'react';
import { useNavigate } from 'react-router-dom';

const PaymentCancelled = () => {
  const navigate = useNavigate();

  return (
    <div className="payment-cancelled-container">
      <h1>Payment Cancelled</h1>
      <p>Your payment was cancelled. No charges were made.</p>
      
      <div className="action-buttons">
        <button onClick={() => navigate('/nfts')}>Return to NFT Marketplace</button>
        <button onClick={() => navigate('/')}>Go to Home</button>
      </div>
    </div>
  );
};

export default PaymentCancelled;