// EdgewareWallet.js
import React, { useState, useEffect, useCallback, useRef } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { FaPaperPlane, FaQrcode, FaLayerGroup, FaCheckCircle, FaTimes, FaArrowUp, FaArrowDown, FaCoins, FaCopy } from 'react-icons/fa';
import { QRCodeSVG } from 'qrcode.react';
import Modal from 'react-modal';
import {
  getEdgewareBalance,
  sendEdgeware,
  stakeEdgeware,
  unstakeEdgeware,
  getEdgewareTransactions,
  getEdgewareValidators,
  estimateTotalFees,
  refreshEdgewareData,
  getTotalStakableBalance,
} from '../../services/api'; // Update the import path as per your project structure
import { useUser } from '../../contexts/UserContext'; // Update the import path as per your project structure
import '../../styles/EdgewareWallet.css'; // Update the import path as per your project structure
import edgewareLogo from '../../styles/img/edgeware-dark-logomark.svg'; // Update the import path as per your project structure

Modal.setAppElement('#root');

const MIN_TRANSACTION_AMOUNT = 5;
const MIN_UNSTAKE_AMOUNT = 1;

const formatBalance = (balance) => parseFloat(balance).toFixed(5);

const LoadingIcon = () => (
  <svg
    className="loading-icon"
    x="0px"
    y="0px"
    viewBox="0 0 37 37"
    height="37"
    width="37"
    preserveAspectRatio="xMidYMid meet"
  >
    <path
      className="track"
      fill="none"
      strokeWidth="5"
      pathLength="100"
      d="M0.37 18.5 C0.37 5.772 5.772 0.37 18.5 0.37 
      S36.63 5.772 36.63 18.5 
      S31.228 36.63 18.5 36.63 
      S0.37 31.228 0.37 18.5">
    </path>
    <path
      className="car"
      fill="none"
      strokeWidth="5"
      pathLength="100"
      d="M0.37 18.5 C0.37 5.772 5.772 0.37 18.5 0.37 
      S36.63 5.772 36.63 18.5 
      S31.228 36.63 18.5 36.63 
      S0.37 31.228 0.37 18.5">
    </path>
  </svg>
);

const EdgewareWallet = () => {
  const { user } = useUser();
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [balance, setBalance] = useState({ total: '0', staked: '0', unlocked: '0', unlocking: '0' });
  const [addresses, setAddresses] = useState({ wasm: '', evm: '' });
  const [transactions, setTransactions] = useState([]);
  const [stakingInfo, setStakingInfo] = useState(null);
  const [activeTab, setActiveTab] = useState('balance');
  const [sendAmount, setSendAmount] = useState('');
  const [sendRecipient, setSendRecipient] = useState('');
  const [sendAddressType, setSendAddressType] = useState('wasm');
  const [stakeAmount, setStakeAmount] = useState('');
  const [unstakeAmount, setUnstakeAmount] = useState('');
  const [selectedValidator, setSelectedValidator] = useState('');
  const [validators, setValidators] = useState([]);
  const [estimatedFee, setEstimatedFee] = useState('0');
  const [isActionLoading, setIsActionLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showStakeConfirmation, setShowStakeConfirmation] = useState(false);
  const [showUnstakeConfirmation, setShowUnstakeConfirmation] = useState(false);
  const [transactionDetails, setTransactionDetails] = useState(null);
  const [stakeDetails, setStakeDetails] = useState(null);
  const [unstakeDetails, setUnstakeDetails] = useState(null);
  const [showResult, setShowResult] = useState(false);
  const [resultMessage, setResultMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const observer = useRef();

  const lastTransactionElementRef = useCallback(
    (node) => {
      if (isLoadingMore) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          loadMoreTransactions();
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoadingMore, hasMore]
  );

  useEffect(() => {
    const fetchInitialData = async () => {
      setIsInitialLoading(true);
      try {
        await fetchWalletData();
        await fetchValidators();
        await fetchTotalStakableBalance();
        await loadTransactions();
      } catch (error) {
        console.error('Error fetching initial data:', error);
      } finally {
        setIsInitialLoading(false);
      }
    };

    fetchInitialData();
  }, []);

  useEffect(() => {
    if (sendAmount && sendRecipient && sendAddressType) {
      estimateTransactionFee();
    }
  }, [sendAmount, sendRecipient, sendAddressType]);

  const fetchWalletData = async (refresh = true) => {
    try {
      let data;
      if (refresh) {
        data = await refreshEdgewareData();
      } else {
        data = await getEdgewareBalance();
      }
      if (data) {
        setBalance(data.balance);
        setStakingInfo(data.stakingInfo);
        setAddresses({
          wasm: data.addresses.wasm_address,
          evm: data.addresses.evm_address,
        });
      }
    } catch (error) {
      console.error('Error fetching wallet data:', error);
    }
  };

  const loadTransactions = async () => {
    setIsLoadingMore(true);
    try {
      const response = await getEdgewareTransactions(1);
      const filteredTransactions = filterTransactions(response.transactions);
      setTransactions(filteredTransactions);
      setHasMore(response.hasMore);
      setPage(2);
    } catch (error) {
      console.error('Error loading transactions:', error);
    } finally {
      setIsLoadingMore(false);
    }
  };

  const loadMoreTransactions = async () => {
    if (isLoadingMore || !hasMore) return;
    setIsLoadingMore(true);
    try {
      const response = await getEdgewareTransactions(page);
      const filteredTransactions = filterTransactions(response.transactions);
      setTransactions((prev) => [...prev, ...filteredTransactions]);
      setHasMore(response.hasMore);
      setPage((prev) => prev + 1);
    } catch (error) {
      console.error('Error loading more transactions:', error);
    } finally {
      setIsLoadingMore(false);
    }
  };

  const filterTransactions = (txs) => {
    return txs.filter(
      (tx) =>
        tx.transaction_type === 'stake' ||
        tx.transaction_type === 'unstake' ||
        tx.transaction_type === 'send' ||
        tx.transaction_type === 'wasm_to_evm' ||
        tx.transaction_type === 'evm_to_wasm' ||
        tx.transaction_type === 'evm_withdraw_to_wasm' ||
        tx.transaction_type === 'evm_to_converted_address'
    );
  };

  const fetchValidators = async () => {
    try {
      const validatorsData = await getEdgewareValidators();
      setValidators(validatorsData);
    } catch (error) {
      console.error('Error fetching validators:', error);
    }
  };

  const fetchTotalStakableBalance = async () => {
    try {
      const balance = await getTotalStakableBalance();
      // Use the balance as needed
    } catch (error) {
      console.error('Error fetching total stakable balance:', error);
    }
  };

  const handleMaxAmount = () => {
    setSendAmount(formatBalance(balance.unlocked));
  };

  const estimateTransactionFee = async () => {
    try {
      if (!sendAmount || isNaN(parseFloat(sendAmount))) {
        setEstimatedFee('0');
        return;
      }
      const response = await estimateTotalFees('send', {
        amount: sendAmount,
        fromType: addresses.wasm ? 'wasm' : 'evm',
        toType: sendAddressType,
        toAddress: sendRecipient || '0x0000000000000000000000000000000000000000',
      });
      if (typeof response !== 'number' && typeof response !== 'string') {
        console.error('Invalid fee response:', response);
        setEstimatedFee('0');
        return;
      }
      const fee = typeof response === 'number' ? response.toString() : response;
      setEstimatedFee(fee);
    } catch (error) {
      console.error('Error estimating fee:', error);
      setEstimatedFee('0');
    }
  };

  const handleSend = async (e) => {
    e.preventDefault();
    setIsActionLoading(true);
    if (parseFloat(sendAmount) < MIN_TRANSACTION_AMOUNT) {
      setResultMessage(`Minimum send amount is ${MIN_TRANSACTION_AMOUNT} EDG`);
      setIsSuccess(false);
      setShowResult(true);
      setIsActionLoading(false);
      return;
    }
    try {
      const response = await estimateTotalFees('send', {
        amount: sendAmount,
        fromType: addresses.wasm ? 'wasm' : 'evm',
        toType: sendAddressType,
        toAddress: sendRecipient,
      });

      if (typeof response !== 'number' && typeof response !== 'string') {
        throw new Error('Invalid fee response');
      }

      const fee = typeof response === 'number' ? response.toString() : response;
      const amountToSendEDG = parseFloat(sendAmount);
      const feeEDG = parseFloat(fee);
      const actualSendAmountEDG = amountToSendEDG - feeEDG;

      if (actualSendAmountEDG <= 0) {
        throw new Error('Amount is too small to cover the transaction fee');
      }

      if (amountToSendEDG > parseFloat(balance.unlocked)) {
        throw new Error('Insufficient unlocked balance');
      }

      setTransactionDetails({
        amount: sendAmount,
        actualSendAmount: actualSendAmountEDG.toFixed(18),
        recipient: sendRecipient,
        addressType: sendAddressType,
        fee: fee,
      });

      setShowConfirmation(true);
    } catch (error) {
      console.error('Error preparing transaction:', error);
      setResultMessage(`Failed to prepare transaction: ${error.message}`);
      setIsSuccess(false);
      setShowResult(true);
    } finally {
      setIsActionLoading(false);
    }
  };

  const confirmSend = async () => {
    setIsProcessing(true);
    setShowConfirmation(false);
    try {
      await sendEdgeware(
        transactionDetails.actualSendAmount,
        transactionDetails.addressType,
        transactionDetails.recipient
      );
      setResultMessage('Transaction sent successfully!');
      setIsSuccess(true);
    } catch (error) {
      console.error('Error sending transaction:', error);
      setResultMessage(`Failed to send transaction: ${error.message}`);
      setIsSuccess(false);
    } finally {
      setIsProcessing(false);
      setShowResult(true);
    }
  };

  const handleStake = async (e) => {
    e.preventDefault();
    setIsActionLoading(true);
    if (parseFloat(stakeAmount) < MIN_TRANSACTION_AMOUNT) {
      setResultMessage(`Minimum staking amount is ${MIN_TRANSACTION_AMOUNT} EDG`);
      setIsSuccess(false);
      setShowResult(true);
      setIsActionLoading(false);
      return;
    }
    try {
      const fee = await estimateTotalFees('stake', {
        amount: stakeAmount,
        validatorAddress: selectedValidator,
      });

      const validatorName = validators.find((v) => v.address === selectedValidator)?.name || '';

      setStakeDetails({
        amount: stakeAmount,
        validator: validatorName,
        fee: fee.toString(),
      });

      setShowStakeConfirmation(true);
    } catch (error) {
      console.error('Error preparing stake:', error);
      setResultMessage(`Failed to prepare stake: ${error.message}`);
      setIsSuccess(false);
      setShowResult(true);
    } finally {
      setIsActionLoading(false);
    }
  };

  const confirmStake = async () => {
    setIsProcessing(true);
    setShowStakeConfirmation(false);
    try {
      await stakeEdgeware(stakeDetails.amount, selectedValidator);
      setResultMessage('Tokens staked successfully!');
      setIsSuccess(true);
    } catch (error) {
      console.error('Error staking tokens:', error);
      setResultMessage('Failed to stake tokens. Please try again.');
      setIsSuccess(false);
    } finally {
      setIsProcessing(false);
      setShowResult(true);
    }
  };

  const handleUnstake = async (e) => {
    e.preventDefault();
    setIsActionLoading(true);
    if (parseFloat(unstakeAmount) < MIN_UNSTAKE_AMOUNT) {
      setResultMessage(`Minimum unstaking amount is ${MIN_UNSTAKE_AMOUNT} EDG`);
      setIsSuccess(false);
      setShowResult(true);
      setIsActionLoading(false);
      return;
    }
    try {
      const fee = await estimateTotalFees('unstake', {
        amount: unstakeAmount,
      });

      setUnstakeDetails({
        amount: unstakeAmount,
        fee: fee.toString(),
      });

      setShowUnstakeConfirmation(true);
    } catch (error) {
      console.error('Error preparing unstake:', error);
      setResultMessage(`Failed to prepare unstake: ${error.message}`);
      setIsSuccess(false);
      setShowResult(true);
    } finally {
      setIsActionLoading(false);
    }
  };

  const confirmUnstake = async () => {
    setIsProcessing(true);
    setShowUnstakeConfirmation(false);
    try {
      await unstakeEdgeware(unstakeDetails.amount);
      setResultMessage(
        'Tokens unstaked successfully! They will be automatically withdrawn when available.'
      );
      setIsSuccess(true);
    } catch (error) {
      console.error('Error unstaking tokens:', error);
      setResultMessage('Failed to unstake tokens. Please try again.');
      setIsSuccess(false);
    } finally {
      setIsProcessing(false);
      setShowResult(true);
    }
  };

  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      console.log('Address copied to clipboard');
    } catch (err) {
      console.warn('Clipboard API not available, falling back to execCommand', err);

      const textArea = document.createElement('textarea');
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        document.execCommand('copy');
        console.log('Address copied to clipboard using execCommand');
      } catch (err) {
        console.error('Fallback: Oops, unable to copy', err);
      }

      document.body.removeChild(textArea);
    }
  };

  const closeResultModal = () => {
    setShowResult(false);
    fetchWalletData(true);
  };

  const getTransactionType = (type) => {
    switch (type) {
      case 'stake':
        return 'Stake';
      case 'unstake':
        return 'Unstake';
      case 'send':
      case 'wasm_to_evm':
      case 'evm_to_wasm':
      case 'evm_withdraw_to_wasm':
      case 'evm_to_converted_address':
        return 'Withdraw';
      default:
        return type;
    }
  };

  const formatAddress = (address) => {
    if (address.length > 20) {
      return `${address.slice(0, 10)}...${address.slice(-10)}`;
    }
    return address;
  };

  if (isInitialLoading) {
    return (
      <div className="edgeware-wallet-page">
        <div className="frame-item">
          <img src={edgewareLogo} alt="Edgeware Logo" className="edgeware-logo-image" />
          <div className="card-content">
            <div className="wallet-balance">
              <p className="balance-amount">
                <Skeleton width={200} height={32} />
              </p>
              <div className="balance-details">
                <p>
                  <Skeleton width={150} />
                </p>
                <p>
                  <Skeleton width={150} />
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="wallet-actions">
          <Skeleton width={100} height={80} style={{ margin: '0 10px' }} />
          <Skeleton width={100} height={80} style={{ margin: '0 10px' }} />
          <Skeleton width={100} height={80} style={{ margin: '0 10px' }} />
        </div>

        <div className="transaction-list">
          <h3>Transaction History</h3>
          {[...Array(5)].map((_, index) => (
            <div key={index} className="transaction-item">
              <div className="transaction-left">
                <Skeleton circle={true} height={20} width={20} />
                <div className="transaction-info">
                  <Skeleton width={100} />
                  <Skeleton width={80} />
                </div>
              </div>
              <div className="transaction-right">
                <Skeleton width={50} />
                <Skeleton width={80} />
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className="edgeware-wallet-page">
      {isActionLoading && (
        <div className="loading-overlay">
          <LoadingIcon />
          <p>Processing...</p>
        </div>
      )}

      <div className="frame-item">
        <img src={edgewareLogo} alt="Edgeware Logo" className="edgeware-logo-image" />
        <div className="card-content">
          <div className="wallet-balance">
            <p className="balance-amount">
              <FaCoins /> {formatBalance(balance.total)} EDG
            </p>
            <div className="balance-details">
              <p>
                <span>Available:</span> {formatBalance(balance.unlocked)} EDG
              </p>
              <p>
                <span>Staked:</span>{' '}
                {formatBalance(parseFloat(balance.staked) + parseFloat(balance.unlocking))} EDG
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="wallet-actions">
        <button
          className={`action-button ${activeTab === 'send' ? 'active' : ''}`}
          onClick={() => setActiveTab('send')}
        >
          <FaPaperPlane />
          <span>Send</span>
        </button>
        <button
          className={`action-button ${activeTab === 'receive' ? 'active' : ''}`}
          onClick={() => setActiveTab('receive')}
        >
          <FaQrcode />
          <span>Receive</span>
        </button>
        <button
          className={`action-button ${activeTab === 'stake' ? 'active' : ''}`}
          onClick={() => setActiveTab('stake')}
        >
          <FaLayerGroup />
          <span>Stake</span>
        </button>
      </div>

      {activeTab === 'send' && (
        <div className="send-form">
          <h3>Send EDG</h3>
          <form onSubmit={handleSend}>
            <div className="input-with-max">
              <input
                type="text"
                value={sendAmount}
                onChange={(e) => setSendAmount(e.target.value)}
                placeholder="Amount"
              />
              <button type="button" onClick={handleMaxAmount}>
                Max
              </button>
            </div>
            <p>Available: {formatBalance(balance.unlocked)} EDG</p>
            <div className="address-type-selector">
              <button
                type="button"
                className={sendAddressType === 'wasm' ? 'active' : ''}
                onClick={() => setSendAddressType('wasm')}
              >
                WASM
              </button>
              <button
                type="button"
                className={sendAddressType === 'evm' ? 'active' : ''}
                onClick={() => setSendAddressType('evm')}
              >
                EVM
              </button>
            </div>
            <input
              type="text"
              value={sendRecipient}
              onChange={(e) => setSendRecipient(e.target.value)}
              placeholder="Recipient Address"
            />
            {estimatedFee !== '0' && <p>Estimated Fee: {formatBalance(estimatedFee)} EDG</p>}
            <button
              type="submit"
              disabled={
                !sendAmount ||
                !sendRecipient ||
                parseFloat(sendAmount) > parseFloat(balance.unlocked) ||
                isActionLoading
              }
            >
              {isActionLoading ? 'Preparing...' : 'Send'}
            </button>
          </form>
        </div>
      )}

      {activeTab === 'receive' && (
        <div className="receive-info">
          <h3>Receive EDG</h3>
          <div className="address-type-switch">
            <button
              className={sendAddressType === 'wasm' ? 'active' : ''}
              onClick={() => setSendAddressType('wasm')}
            >
              WASM
            </button>
            <button
              className={sendAddressType === 'evm' ? 'active' : ''}
              onClick={() => setSendAddressType('evm')}
            >
              EVM
            </button>
          </div>
          <div className="qr-code-container">
            <QRCodeSVG value={addresses[sendAddressType] || ''} size={200} />
          </div>
          <div className="address-container">
            <p className="address-label">Your {sendAddressType.toUpperCase()} Address:</p>
            <p className="address">
              {addresses[sendAddressType] || `Address not available (${sendAddressType})`}
              {addresses[sendAddressType] && (
                <button
                  className="copy-button"
                  onClick={() => copyToClipboard(addresses[sendAddressType])}
                >
                  <FaCopy />
                </button>
              )}
            </p>
          </div>
        </div>
      )}

      {activeTab === 'stake' && (
        <div className="stake-form">
          <h3>Stake EDG</h3>
          <form onSubmit={handleStake}>
            <div className="input-with-max">
              <input
                type="text"
                value={stakeAmount}
                onChange={(e) => setStakeAmount(e.target.value)}
                placeholder={`Amount (minimum ${MIN_TRANSACTION_AMOUNT} EDG)`}
              />
              <button type="button" onClick={() => setStakeAmount(formatBalance(balance.unlocked))}>
                Max
              </button>
            </div>
            <p>Available for Staking: {formatBalance(balance.unlocked)} EDG</p>
            <select value={selectedValidator} onChange={(e) => setSelectedValidator(e.target.value)}>
              <option value="">Select Validator</option>
              {validators.map((validator) => (
                <option key={validator.address} value={validator.address}>
                  {validator.name} - Commission: {validator.commission}%
                </option>
              ))}
            </select>
            <button
              type="submit"
              disabled={
                parseFloat(stakeAmount) < MIN_TRANSACTION_AMOUNT ||
                !selectedValidator ||
                isActionLoading
              }
            >
              {isActionLoading ? 'Preparing...' : 'Stake'}
            </button>
          </form>

          <h4>Staking Info</h4>
          {stakingInfo ? (
            <div>
              <p>Total Staked: {formatBalance(stakingInfo.total)} EDG</p>
              <p>Active Stake: {formatBalance(stakingInfo.active)} EDG</p>
              <p>
                Unlocking:{' '}
                {formatBalance(
                  stakingInfo.unlocking.reduce((sum, item) => sum + parseFloat(item.value), 0)
                )}{' '}
                EDG
              </p>
              {stakingInfo.unlocking.length > 0 && (
                <div>
                  <p className="info-text">Unlocking details:</p>
                  <ul>
                    {stakingInfo.unlocking.map((unlock, index) => (
                      <li key={index}>
                        {formatBalance(unlock.value)} EDG, unlocks at era {unlock.era}
                      </li>
                    ))}
                  </ul>
                  <p className="info-text">
                    Unlocking funds will be automatically withdrawn when available.
                  </p>
                </div>
              )}
            </div>
          ) : (
            <p>No staking information available</p>
          )}

          <h4>Unstake EDG</h4>
          <form onSubmit={handleUnstake}>
            <div className="input-with-max">
              <input
                type="text"
                value={unstakeAmount}
                onChange={(e) => setUnstakeAmount(e.target.value)}
                placeholder={`Amount to unstake (minimum ${MIN_UNSTAKE_AMOUNT} EDG)`}
              />
              <button
                type="button"
                onClick={() => setUnstakeAmount(formatBalance(stakingInfo?.active || '0'))}
              >
                Max
              </button>
            </div>
            <p>Available for Unstaking: {formatBalance(stakingInfo?.active || '0')} EDG</p>
            <button
              type="submit"
              disabled={
                parseFloat(unstakeAmount) < MIN_UNSTAKE_AMOUNT ||
                parseFloat(unstakeAmount) > parseFloat(stakingInfo?.active || '0') ||
                isActionLoading
              }
            >
              {isActionLoading ? 'Preparing...' : 'Unstake'}
            </button>
          </form>
        </div>
      )}

      <div className="transaction-list">
        <h3>Transaction History</h3>
        {transactions.map((tx, index) => (
          <div
            key={index}
            className="transaction-item"
            ref={index === transactions.length - 1 ? lastTransactionElementRef : null}
          >
            <div className="transaction-left">
              <div className="transaction-icon">
                {getTransactionType(tx.transaction_type) === 'Withdraw' ? (
                  <FaArrowUp />
                ) : (
                  <FaArrowDown />
                )}
              </div>
              <div className="transaction-info">
                <span className="transaction-type">{getTransactionType(tx.transaction_type)}</span>
                <span className="transaction-amount">{formatBalance(tx.amount)} EDG</span>
              </div>
            </div>
            <div className="transaction-right">
              <span className={`transaction-status ${tx.status}`}>{tx.status}</span>
              <span className="transaction-time">{new Date(tx.timestamp).toLocaleString()}</span>
              {tx.explorer_link && (
                <a
                  href={
                    tx.chain_type === 'wasm'
                      ? `https://edgscan.ink/#/extrinsics/${tx.extrinsic_number}`
                      : tx.explorer_link
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  className="explorer-link"
                >
                  View in Explorer
                </a>
              )}
            </div>
          </div>
        ))}
        {isLoadingMore && <div className="loading">Loading more transactions...</div>}
        {!hasMore && <div className="no-more-transactions">No more transactions</div>}
      </div>

      <Modal
        isOpen={showConfirmation}
        onRequestClose={() => setShowConfirmation(false)}
        contentLabel="Confirm Transaction"
        className="confirmation-modal"
        overlayClassName="confirmation-modal-overlay"
      >
        <h2>Confirm Transaction</h2>
        {transactionDetails && (
          <div>
            <p>Amount: {formatBalance(transactionDetails.amount)} EDG</p>
            <p>Recipient: {formatAddress(transactionDetails.recipient)}</p>
            <p>Chain Type: {transactionDetails.addressType.toUpperCase()}</p>
            <p>Estimated Fee: {formatBalance(transactionDetails.fee)} EDG</p>
            <p>You will send: {formatBalance(transactionDetails.amount)} EDG</p>
            <p>Recipient will receive: {formatBalance(transactionDetails.actualSendAmount)} EDG</p>
          </div>
        )}
        <div className="confirmation-buttons">
          <button onClick={confirmSend} disabled={isProcessing}>
            {isProcessing ? (
              <>
                <LoadingIcon />
                <span>Processing...</span>
              </>
            ) : (
              'Confirm'
            )}
          </button>
          <button onClick={() => setShowConfirmation(false)} disabled={isProcessing}>
            Cancel
          </button>
        </div>
      </Modal>

      <Modal
        isOpen={showStakeConfirmation}
        onRequestClose={() => setShowStakeConfirmation(false)}
        contentLabel="Confirm Staking"
        className="confirmation-modal"
        overlayClassName="confirmation-modal-overlay"
      >
        <h2>Confirm Staking</h2>
        {stakeDetails && (
          <div>
            <p>Amount to Stake: {formatBalance(stakeDetails.amount)} EDG</p>
            <p>Selected Validator: {stakeDetails.validator}</p>
            <p>Estimated Fee: {formatBalance(stakeDetails.fee)} EDG</p>
          </div>
        )}
        <div className="confirmation-buttons">
          <button onClick={confirmStake} disabled={isProcessing}>
            {isProcessing ? (
              <>
                <LoadingIcon />
                <span>Processing...</span>
              </>
            ) : (
              'Confirm'
            )}
          </button>
          <button onClick={() => setShowStakeConfirmation(false)} disabled={isProcessing}>
            Cancel
          </button>
        </div>
      </Modal>

      <Modal
        isOpen={showUnstakeConfirmation}
        onRequestClose={() => setShowUnstakeConfirmation(false)}
        contentLabel="Confirm Unstaking"
        className="confirmation-modal"
        overlayClassName="confirmation-modal-overlay"
      >
        <h2>Confirm Unstaking</h2>
        {unstakeDetails && (
          <div>
            <p>Amount to Unstake: {formatBalance(unstakeDetails.amount)} EDG</p>
            <p>Estimated Fee: {formatBalance(unstakeDetails.fee)} EDG</p>
            <p className="warning">
              Warning: It will take 56 ERAS to withdraw funds from staking after unstaking. Funds
              will be automatically withdrawn when available.
            </p>
          </div>
        )}
        <div className="confirmation-buttons">
          <button onClick={confirmUnstake} disabled={isProcessing}>
            {isProcessing ? (
              <>
                <LoadingIcon />
                <span>Processing...</span>
              </>
            ) : (
              'Confirm'
            )}
          </button>
          <button onClick={() => setShowUnstakeConfirmation(false)} disabled={isProcessing}>
            Cancel
          </button>
        </div>
      </Modal>

      <Modal
        isOpen={isProcessing}
        contentLabel="Processing Transaction"
        className="processing-modal"
        overlayClassName="processing-modal-overlay"
      >
        <div className="processing-content">
          <LoadingIcon />
          <h2>Processing</h2>
          <p>Please wait while we process your transaction...</p>
        </div>
      </Modal>

      <Modal
        isOpen={showResult}
        onRequestClose={closeResultModal}
        contentLabel="Transaction Result"
        className="result-modal"
        overlayClassName="result-modal-overlay"
      >
        <div className={`result-content ${isSuccess ? 'success' : 'error'}`}>
          {isSuccess ? (
            <FaCheckCircle className="result-icon success" />
          ) : (
            <div className="error-icon">
              <FaTimes />
            </div>
          )}
          <h2>{isSuccess ? 'Success' : 'Error'}</h2>
          <p>{resultMessage}</p>
          {isSuccess && (
            <p className="processing-message">
              Your transaction is being processed. Please check your transaction history for updates.
            </p>
          )}
          <button onClick={closeResultModal} className="back-to-wallet-button">
            Back to Wallet
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default EdgewareWallet;