import React, { createContext, useContext, useState } from 'react';

const PurchaseContext = createContext();

export const PurchaseProvider = ({ children }) => {
  const [purchaseId, setPurchaseId] = useState(null);

  const clearPurchaseId = () => setPurchaseId(null);

  return (
    <PurchaseContext.Provider value={{ purchaseId, setPurchaseId, clearPurchaseId }}>
      {children}
    </PurchaseContext.Provider>
  );
};

export const usePurchaseContext = () => {
  const context = useContext(PurchaseContext);
  if (context === undefined) {
    throw new Error('usePurchaseContext must be used within a PurchaseProvider');
  }
  return context;
};